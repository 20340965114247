/*
    DEMO STYLE
*/

/*@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";*/

/* ===== Scrollbar CSS ===== */
/* Firefox */



html , body , :root{
    height: 100%;
}


body {
    font-family: 'Archivo';
}
#content{
    background: url("../images/bg.png") no-repeat;
    background-size: cover;
    width: 100%;
}
.backdrop-filter{
    filter: blur(8px);
}
body::-webkit-scrollbar {
    display: none;
}
.user_management::-webkit-scrollbar, .user-management::-webkit-scrollbar{
    display: none;
}
.tab-content::-webkit-scrollbar {
    display: none;
}
body *{
    font-family: 'Archivo';
}

p {
    font-family: 'Archivo';
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 1.042vw 0.694vw;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 2.778vw;
    box-shadow: 0.069vw 0.069vw 0.208vw rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 0.069vw;
    border-bottom: 0.069vw dashed #ddd;
    margin: 2.778vw 0;
}

i,
span {
    display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    min-width: 17.361vw;
    max-width: 17.361vw;
    background: #fff;
    color: #fff;
    transition: all 0.27s;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    box-shadow: 0 0.833vw 2.778vw rgba(169, 169, 169, 0.15);
}

#sidebar.collapsed {
    min-width: 5vw;
    max-width: 5vw;
    text-align: left;
}


#sidebar.collapsed .sidebar-header strong {
    display: block;
}

#sidebar ul li a {
    text-align: left;
    /*color: ;*/
}

#sidebar.collapsed ul li a {
    padding: 0.625vw 0.347vw 0.069vw;
    text-align: left;
    font-size: 0.972vw;
}
#sidebar:not(.collapsed) .icon.icon-Menu:before{
    content: "\901d";
    font-size: 1.111vw;
    top: -0.139vw;
    position: relative;
}
#sidebar ul li.active a{
    font-weight: 400;
    font-size: 0.972vw;
    line-height: 1.250vw;
    color: #000000;
}
button:focus{
    outline: 0;
}
.icons-plus{
    color: #fff;
    font-size: 2.222vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin:0 ;
    line-height: 1.1;
}
#addVersion .modal-footer {
    padding: 0;
    margin-top: 0.417vw;
    border: 0;
    position: relative;
    top: 0;
}
.opacity-0{
    opacity: 0;
    visibility: hidden;
}
#sidebar ul li a {
    font-size: 0.972vw;
    font-family: 'Archivo';
    line-height: 1.250vw;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 300;
}


#sidebar.collapsed ul ul a {
    padding: 0.694vw !important;
}

#sidebar.collapsed .dropdown-toggle::after {
    top: auto;
    bottom: 0.694vw;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

#sidebar .sidebar-header {
    background: #fff;
    border-bottom: 0.069vw solid rgba(141, 130, 147, 0.12);
}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar ul.components {
    padding: 0.972vw 1.111vw 0.972vw 1.319vw;
    /*border-bottom: 0.069vw solid #47748b;*/
}

#sidebar ul li a {
    padding: 0.625vw 0.347vw 0.069vw;
    display: flex;
    white-space: nowrap;
    align-items: center;
}

#sidebar ul li:hover {
    color:rgba(45, 47, 50, 1);
}
#sidebar ul li:hover span, #sidebar ul li.active span , #sidebar ul li:focus span , #sidebar ul li:focus-visible span , #sidebar ul li a:hover span, #sidebar ul li a:focus span, #sidebar ul li a:focus-visible span {
    color: rgba(45, 47, 50, 1);

}
#sidebar ul li:focus , #sidebar ul li:focus-visible ,#sidebar ul li a:focus , #sidebar ul li a:focus-visible{
    outline: 0;
    border: 0;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
}
.centered_content{
    display: flex;
    align-items: center;
}
.login-head{
    margin-bottom: 2.778vw;
}

a[data-toggle="collapse"] {
    position: relative;
}
.left-wrapper{
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #46B0E6;
}
.left-wrapper::before {
    position: absolute;
    content: "";
    width: 94%;
    height: 100%;
    left: -69%;
    background: #39A6DE;
    transform: rotate(-26deg);
    top: 28%;
}
#MainBox{
    overflow: hidden;
}
.left-wrapper span.curls{
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;

}
.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 1.389vw;
    transform: translateY(-50%);
}

.left-wrapper .content-wrapper h1{
    font-weight: 700;
    font-family: 'Archivo';
    font-size: 4.167vw;
    line-height: 5.278vw;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 1.111vw;
}
.left-wrapper .content-wrapper h3{
    font-weight: 100;
    font-size: 2.778vw;
    font-family: 'Archivo';
    margin-bottom: 2.22vw;
    line-height: 1.25vw;
    color: #FFFFFF;
    letter-spacing: -0.056vw;
    left: 1.111vw;
    position: relative;
}
.left-wrapper .content-wrapper p{
    font-style: normal;
    font-family: 'Archivo';

    font-weight: 400;
    font-size: 1.111vw;
    line-height: 1.25vw;
    color: #FFFFFF;


}

.copyrights-p{
    font-weight: 400;
    font-size: 0.972vw;
    line-height: 1.250vw;
    bottom: 2.014vw;
    color: #FFF;
    left: 50%;
    letter-spacing: -0.007vw;
    position: absolute;
    transform: translate(-50%);
    z-index: 9;
}
.switch:focus {
    outline: 1px solid black;
}
.main-wrapper.login-wrapper{
    position: relative;
    left: 0;
    top: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}
@media(max-width: 1440px){
    .left-wrapper::before {
        position: absolute;
        content: "";
        width: 94%;
        height: 100%;
        left: -61%;
        background: #39A6DE;
        transform: rotate(-26deg);
        top: 35%;
    }


}
@media(min-width: 1441px){
    div.actions-grid {
        flex: 0 0 9.5%;
        max-width: 9.5%;
    }
}

.left-wrapper .img-wrapper{
    position: relative;
    top: -0.139vw;
    z-index: 8;
}
.login-head .logo-area img{
    width: 4.514vw;
}
.content-wrapper{
    position: relative;
    top: -3.333vw;
    z-index: 10;
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 2.083vw !important;
    background: #2D2F32;
}

ul.CTAs {
    padding: 1.389vw;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 0.347vw;
    margin-bottom: 0.347vw;
}
.icon.side-bar-icon{
    font-size: 1.736vw;
}
a.download {
    background: #fff;
    color: #2D2F32;
}

a.article,
a.article:hover {
    background: #2D2F32 !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 0.833vw 0.556vw;
    transition: all 0.3s;
    margin-left: 5vw;
    height: 100vh;
}
.menu-backdrop {
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
}
.search-container-main{
    display: none;
}
.avatar-area{
    display: flex;
    padding: 1.389vw 1.042vw 0.694vw;
}
.avatar-area h1{
    font-style: normal;
    font-weight: 700;
    font-size: 1.111vw;
    margin: 0;
    color: #2D2F32;
}
.avatar-area a{
    font-style: normal;
    font-weight: 400;
    font-size: 0.833vw;
    line-height: 1.111vw;
    color: #767676;
}
.avatar-area .avatar-details{
    padding: 0 1.736vw 0 0.764vw;
}
.avatarWrapper .dropdown-menu.show{
    padding: 0;
    left: 5.208vw !important;
    top: 2.986vw !important;
}
.avatarWrapper .dropdown-items{
    padding: 0 0 0.417vw 0;
}
.dropdown-item {
    height: 2.569vw;
    padding-top: 0;
    display: flex;
    align-items: center;
}
.user_data{
    display: flex;
    align-items: center;
}
.user_details{
    display: flex;
    flex-direction: column;
    margin-left: 0.764vw;
}
.user_details .grid-reg-text{
    padding-left: 0;
}
.user_image_wrapper{
    height: 2.778vw;
    width: 2.778vw;
    border-radius: 50%;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    overflow: hidden;
}
.pageTitle {
    font-size: 1.67vw;
    line-height: 1.667vw;
    color: #2D2F32;
    font-family: 'Archivo';
    margin: 0.694vw 0 0 0;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 1024px) {
    #sidebar {
        min-width: 17.361vw;
        max-width: 17.361vw;
        text-align: left;
        margin-left: 0 !important;
        position: absolute;
        z-index: 3;
    }

    .dropdown-toggle::after {
        top: auto;
        bottom: 0.694vw;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }

    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }
    #sidebar .sidebar-header strong {
        display: block;
    }
    #sidebarCollapse span {
        display: none;
    }
}


/*-------------------------------------------------------------*/
.HelveticaNeueLTStd-Lt {
    font-family: 'Archivo';
    font-weight: 300;
}
.HelveticaNeueLTStd-Roman {
    font-family: 'Archivo';
    font-weight: 400;
}
.HelveticaNeueLTStd-Md {
    font-family: 'Archivo';
    font-weight: 500;
}
.HelveticaNeueLTStd-Bd {
    font-family: 'Archivo';
    font-weight: 600;
}

html, body {
    font-family: 'Archivo';
}

.form-control::-webkit-input-placeholder { /* Edge */
    color: #000000;
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000000;
}

.form-control::-moz-placeholder {
    color: #2D2F32;
}

.form-control::-ms-input-placeholder {
    color: #000000;
}

.form-control::placeholder {
    color: #000000;
}
#sidebar .nav-text {
    transition: all 0.27s;
    padding-top: 0.139vw;
}
#sidebar.collapsed .nav-text {
    opacity: 0;
    visibility: hidden;
}
#sidebar ul li a {
    border-radius: 0.417vw;
    background-color: transparent;
}
#sidebar ul li.active a {
    color: rgba(45, 47, 50, 1);
}
#sidebar ul li + li {
    margin-top: 2.639vw;
}
.sidebar-expand-collapse {
    padding: 1.389vw 1.667vw;
    margin-top: 0.347vw;
    display: block;white-space: nowrap;
}
#sidebar span.icon {
    display: inline-block;
    margin-right: 1.111vw;
    color: rgb(35, 35, 35);
}
.pageFilters .form-row {
    justify-content: flex-end;
    text-align: right;
    position: relative;
    left: -0.208vw;
    top: 0.208vw;
}
.avatarWrapper {
    text-align: right;
    position: relative;
    top: 0.208vw;
}
.avatarWrapper .dropdown {
    display: inline-block;
    cursor: pointer;
}
.logoWrapper {
   display: none;
}
.sidebarLogoWrapper{
    display: block;
    padding: 1.111vw 0.694vw 1.597vw;
    min-width: 10.417vw;
}
.collapsed .sidebarLogoWrapper{
    min-width: 10.417vw;
}
.collapsed .sidebarLogoWrapper span{
    opacity: 0;
    visibility: hidden;
    height: 0;
    z-index: -1;
}
.avatarWrapper .show-notification .dropdown-menu.show {
    left: 17.708vw !important;
}
.show-notification {
    display: flex !important;


}
li.roles:hover span span.path1::before ,li.roles a:focus span span.path1::before , li.roles.active span span.path3::before , li.active.roles span span.path1::before , li.roles:hover span span.path3::before {
    color: #46B0E6 !important;
}

li.roles:hover span span.path2::before , li.roles a:focus span span.path2::before , li.roles a:focus span span.path4::before  , li.roles:hover span span.path4::before , li.active.roles span span.path2::before , li.active.roles span span.path4::before{
    color: rgb(225, 235, 252) !important;
}

.icon-Group-35481 , .icon-profile-2user , .icon-user-minus span{
    font-family: 'icomoon' !important;
    font-size: 1.736vw;
}
.notification-dropdown .nav-text{
    font-weight: 300;
    font-size: 0.972vw;
    line-height: 1.250vw;
    color: rgba(0, 0, 0, 0.65);
}
.logoWrapper a{
    display: block;
    max-width: 3.472vw;
}
.logoWrapper a span{
    opacity: 0;
    visibility: hidden;
    cursor: default;
    width: 0;
}
.avatarWrapper .dropdown {
    left: -0.972vw;
    margin-left: 0.347vw;
}
.logo-link span {
    font-size: 1.528vw;
    margin-left: 0.972vw;
    line-height: 1.875vw;
    color: #2D2F32;
    font-family: 'Archivo';
    margin-top: 0;
    position: relative;
    top: 0.139vw;
    padding-left: 0.694vw;
    padding-top: 0.417vw;
}
.logo-text:before{
    content: "";
    position: absolute;
    left: -0.139vw;
    top: 0.347vw;
    height: 1.667vw;
    width: 0.069vw;
    background: #C0C0C0;
}
.user-name {
    font-size: 0.903vw;
    line-height: 1.111vw;
    color: #686573;margin-right: 0.694vw;
    font-family: 'Archivo';
}

.pageFilters .form-control::-moz-placeholder {
    color: #2D2F32; font-size: 0.833vw;
    line-height: 0.972vw;opacity: 0.7;
}
#ivrName option,
#clientName option{
    font-family: 'Archivo';
}
.chosen-container.chosen-container-single.chosen-container-active .chosen-single {
    border: 0.069vw solid #39B7E9;
}
.modal .chosen-container-single .chosen-single,
.modal .chosen-container-single {
    width: 100% !important;
    max-width: 100% !important;
}
.chosen-container.chosen-container-single{
    max-width: 100% !important;
}
.modal .chosen-container-single .chosen-single,
.pageFilters .chosen-container-single .chosen-single {
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 2.986vw;
    color: #2D2F32;
    font-family: 'Archivo';
    width: 14.306vw;
    max-width: 100%;
    height: 2.778vw;
    border-radius: 0.278vw;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.069vw;
    background: #DDE6F3 url('../images/caret_drop.svg') no-repeat calc( 100% - 0.903vw) center;
    box-shadow: none;
    text-align: left;
    border: 0.069vw solid transparent;
    padding-left: 0.694vw;
}
.show-large {
    display: block;
}
.show-small {
    display: none;
}
.modal .chosen-container-single .chosen-single {
    background-color: #F6F6F6;
}
.modal .chosen-container-single .chosen-single span,
.pageFilters .chosen-container-single .chosen-single span {
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 2.917vw;
    font-family: 'Archivo';
}
.chosen-container .chosen-results li.active-result{
    text-align: left !important;
}
.dropdown-item.option-has-icon .icon-upload-icon{
    font-size: 1.111vw;
}
.searchField {
    width: 14.306vw;
    height: 2.778vw;
    border-radius: 0.278vw;
    font-size: 0.833vw;
    border: none;
    line-height: 0.972vw;
    color: #2D2F32;
    font-family: 'Archivo';
    background: transparent url('../images/search_field.svg') no-repeat 15px center !important;
    padding-left: 2.569vw;
    border-color: #D8DBE8 !important;
}
.searchField:focus{
    background-color: #FDFEFE !important;
    box-shadow: none;
    border-color: #46B0E6 !important;
}
.logo-n-avatar-wrapper > .row + .row {
    margin-top: 2.083vw;
}
#addIVRBtn:hover,
#addIVRBtn:focus
,#addVersionBtn:hover,
#addVersionBtn:focus{
    opacity: 0.8;
}
.addVersionBtnCon {
    margin-left: 0.139vw;
}
.btn-primary.with-plus-icon {
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.111vw;
    text-align: center;
    font-family: 'Archivo';
    width: auto;
    position: relative;
    border: 0;
    height: 2.778vw;
    padding: 0.208vw 1.319vw 0 2.639vw;
    color: #fff;
    display: flex;
    align-items: center;
    background: #46B0E6;
    border-radius: 0.278vw;
}
.btn-primary.with-plus-icon:before{
    content: "\900b";
    position: absolute;
    font-family: 'icomoon' !important;
    color: #fff;
    left: 1.250vw;
    font-size: 0.833vw;
    font-weight: 300;
    top: 0.833vw;
}
.searchFieldCon .button {
    display: inline-block;
    margin: 0.278vw 0.139vw;
    background: #46B0E6;
    border-radius: 0.417vw;
    font-size: 0.972vw;
    left: -0.139vw;
    padding-left: 2.222vw;
    padding-right: 2.222vw;
    height: 2.778vw;
    line-height: 3.056vw;
    text-align: center;
    color: white;
    text-decoration: none;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.searchFieldCon .button:hover {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    background-color: #1581D6;
    color: white;
}
.searchFieldCon .search-container {
    position: relative;
    display: inline-block;
    margin: 0;
    height: 2.778vw;
    width: 2.778vw;
    vertical-align: bottom;
    left: 0.208vw;
}
.searchFieldCon .mglass {
    display: inline-block;
    pointer-events: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    left: 0.208vw;
    position: relative;
}
.form-row .col-auto{
    transition: 0.4s ease;
}
.custom-margin-right{
    margin-right: 12.153vw;
    transition: 0.4s ease;
}
.searchFieldCon .searchbutton {
    position: absolute;
    font-size: 1.806vw;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 99;
}
.searchFieldCon .search.search-open + .searchbutton {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    background-color: transparent;
    color: black;

}
.searchFieldCon .search {
    position: absolute;
    left: 3.403vw;
    /* Button width-0.069vw (Not 50/100% because that will sometimes show a 0.069vw line between the search box and button) */
    background-color: white;
    outline: none;
    border: none;
    padding: 0;
    width: 0;
    height: 100%;
    z-index: 10;
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
}
.searchFieldCon .search.search-open {
    width: 15vw;
    /* Bar width+0.069vw */
    padding: 0 1.111vw 0 0;
    border: 0.035vw solid #DBDBDB;
    box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.01);
    border-radius: 0.417vw;



}
.searchFieldCon .expandright {
    left: auto;
    right: 0.139vw;
    height: 2.778vw;
    font-size: 0.833vw;
    color: #2D2F32;
}

.searchFieldCon .expandright.search-open {
    padding: 0 2.778vw 0 1.111vw;
}
.custom-margin-right{
}


.col-action > a, .col-action > span {
    display: inline-block;
    vertical-align: middle;
}
.col-action > span {
    margin: 0 0 0 0.694vw;
}

.col-action{
    justify-content: flex-end !important;
}
.col-action .custom-control.custom-switch {
    position: relative;
}
.row-details{
    order: 8;
    background: #F2F9FF;
    padding: 0.694vw !important;
    width: 100%;
    margin: 1.389vw 0 0;
}


.user_management .row.user_table .ivr-grid-status{
    margin-left: 0;
}
.user_management .row.user_table .centered_content{
    justify-content: flex-start !important;
}
.user_management .container-fluid:first-child .row.user_table{
    padding-top: 1.181vw;
}
.user_management  .row.user_table .role-type .ivr-grid-status{
    padding: 0.486vw 0.972vw 0.278vw;
}
.col-xl-3.intello-msg .sup-lang-wrap{
    margin-left: 1.667vw;
    margin-top: 0.417vw;
}
.col-xl-3.intello-msg .sup-lang-wrap p{
    max-width: 93%;
}
.dropdownList.d-md-block{
    display: inline-block !important;
}
.user_table{
    border: 0.069vw solid transparent;
    border-left: 0.208vw solid transparent;
}
.bordered-box{
    border: 0.069vw solid #46B0E6;
    border-left: 0.208vw solid #46B0E6 ;
}
.ivr-grid-con:not(.version-grid-con) > .row {
    padding-left: 0;
    padding-right: 0;
}
.custom-padding{
    padding-left: 4.167vw !important;
    padding-top: 0.347vw !important;
}
.grid-instance-column {
    padding-left: 1.042vw;
}
.saved {
    border-color: #F79E1B;
    color: #F79E1B;
}
.pl-10{
    padding-left: 0.694vw !important;
}
.ivr-grid-heads {
    margin-top: 2.083vw;
    margin-bottom: 0.208vw;
}
.row.user_table .user_details h1{
    font-weight: 500;
    font-size: 0.972vw;
    line-height: 1.181vw;
    margin-bottom: 0.139vw;
    color: #2D2F32;
    letter-spacing: 0.028vw;
}
.row.user_table .user_details a{
    font-style: normal;
    font-weight: 400;
    font-size: 0.833vw;
    line-height: 0.972vw;
    color: #2D2F32;

    opacity: 0.6;
}
.row.user_table .ivr-grid-status{
    font-size: 0.694vw;
    letter-spacing: 0.069vw;
    line-height: 0.833vw;
    text-align: center;
    text-transform: uppercase;
    color: #2D2F32;
    margin-left: 2.431vw;
}
.form-control.search-field{
    border: 0.069vw solid #EAEAEA;
    border-radius: 0.278vw;
    padding-left: 2.4vw;
    min-height: 2.778vw;
    padding-top: 0.556vw;
    font-size: 0.833vw;
    color: #2D2F32;
    max-width: 10.694vw;
}
.login-box .btn-blue:focus{
    border: 0.069vw solid !important;
}
.form-control.search-field::placeholder{
    font-size: 0.833vw;
    line-height: 1.111vw;
    color: #2D2F32;
}
.search-icon-control{
    position: absolute;
    left: 1.458vw;
    top: 0.972vw;
    font-size: 0.833vw;
}
.row.user_table .centered_content{
    display: flex;
    justify-content: center;
    max-width: 9.028vw;
}
.ivr-grid-con:not(.version-grid-con) > .row.user_table.user_table:not(.ivr-grid-heads){
    margin-top: 0;
}
.user_management .container-fluid:nth-child(2n+3) .row.user_table:not(.roles_table), .user_management .container-fluid:nth-child(2n) .row.user_table.roles_table,.user-management .container-fluid:nth-child(2n+3) .row.user_table{
    background: #F9FBFC;
}
.row.user_table.roles_table{
    padding-top: 1.111vw;
    padding-bottom: 0.972vw;
}
.row.user_table{
    background-color: #fff;
    padding-top: 0.764vw;
    padding-bottom: 0.764vw;
    position: relative;
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.111vw;
    color: #686573;
    align-items: center;
}

.permissions_box{
    background: #F2F3F5;
    border-radius: 0.278vw;
    font-weight: 500;
    font-size: 0.694vw;
    line-height: 0.833vw;
    text-align: center;
    color: #2D2F32;
    opacity: 0.7;
    padding: 0.625vw 0.903vw 0.417vw;
    margin-right: 0.347vw;
    margin-bottom: 0.347vw;
}
.user_body{
    box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.07);
}
.ivr-grid-con:not(.version-grid-con) > .row:not(.ivr-grid-heads) {
    background-color: #fff;
    padding: 0.833vw 0;
    position: relative;
    font-weight: 500;
    font-size: 0.903vw;
    line-height: 1.111vw;
    color: #686573;
    font-family: 'Archivo';
    box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.07);
    border-radius: 0.278vw;
    align-items: center;
    min-height: 4.444vw;
}

.clients_icon img:nth-child(2){
    position: relative;
    left: -0.347vw;
}
.clients_icon img:nth-child(3){
    position: relative;

    left: -0.694vw;
}
.clients_icon img:nth-child(4){
    position: relative;

    left: -1.042vw;
}
.clients_icon img:nth-child(5){
    position: relative;

    left: -1.389vw;
}
.clients_icon img:nth-child(6){
    position: relative;
    left: -1.736vw;
}
.row.user_table.ivr-grid-heads{
    margin-bottom: 0;
    min-height: 3.333vw;
    border-bottom: 0.069vw solid #E8E8E8;
    margin-top: 0;
    border-radius: 0.278vw;
    padding-top: 1.111vw;
}
.col-xl-2.grid-status-column .gridHeadText{
    padding-left: 1.944vw;
}

.col-xl-3.grid-instance-head-column .gridHeadText{
    padding-left: 0.347vw;
}
.user_body .tab-pane:not(.active){
    display: none;
}
.user_container .nav.nav-pills{
    margin-top: 1.736vw;
}
.more-icons{
    background: #EBF3FF;
    border-radius: 0.972vw;
    min-height: 1.944vw;
    min-width: 1.944vw;
    max-width: 1.944vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -2.083vw;
    font-style: normal;
    font-size: 0.764vw;
    line-height: 0.903vw;
    text-align: center;
    color: #46B0E6;
}
.clients_icon{
    display: flex;
}
.call-icon{
   text-align: right;
    display: flex;
    justify-content: center;
}
#sidebar ul li.active a span.icon.icon-direct span:first-child::before,#sidebar ul li a:hover span.icon.icon-direct span:first-child::before{
    color:  rgb(225, 235, 252) !important;
    transition: 0.3s ease;
}

#sidebar ul li.active a span.icon.icon-direct span:not(:first-child)::before , #sidebar ul li a:focus span.icon.icon-direct span:not(:first-child)::before,#sidebar ul li a:hover span.icon.icon-direct span:not(:first-child)::before{
    color: #46B0E6 !important;
    transition: 0.3s ease;
}
#sidebar ul li.active a span.icon span:first-child::before,#sidebar ul li a:hover span.icon span:first-child::before , #sidebar ul li a:focus span.icon span:first-child::before{
    color: rgb(225, 235, 252);

    transition: 0.3s ease;
}
ul.components li a span span{
    font-family: icomoon !important;
}
#sidebar ul li.active a span.icon span:not(:first-child)::before , #sidebar ul li a:focus span.icon span:not(:first-child)::before,#sidebar ul li a:hover span.icon span:not(:first-child)::before{
    transition: 0.3s ease;
    color:  #46B0E6;

}
.avatar-margin{
    margin-right: 1.042vw !important;
}
.avatar-name{
    padding: 0 !important;
    margin-right : 1.042vw;
}
.dropdown-menu.notification-container{
    padding: 1.667vw !important;
    border: 0.035vw solid #F1F1F1;
    box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.07);
    border-radius: 0.417vw;
    min-width: 27.153vw;
    top: 0.347vw !important;
}
.notification-header h1{
    font-weight: 700;
    font-size: 1.111vw;
    line-height: 1.389vw;
    color: #2D2F32;
    margin-bottom: 0;
}
.notification-header span{
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.111vw;
    color: #46B0E6;
    background: #F6FAFF;
    border-radius: 1.319vw;
    padding: 0.347vw 0.833vw 0.139vw;
}
.notification-body .notification-content h2{
    font-weight: 500;
    font-size: 0.972vw;
    line-height: 1.111vw;
    color: #2D2F32;
}
.notification-body .notification-content span{
    font-weight: 400;
    font-size: 0.833vw;
    line-height: 1.111vw;
    color: #767676;
}
.notification-body p{
    font-weight: 400;
    font-size: 0.833vw;
    line-height: 1.3;
    color: #585858;
    margin: 0;
}
.notification-body p b{
    font-weight: 500;
    font-family: 'Archivo';

}
.notification-body .img-wrapper span{
    border-radius: 50%;
    background: #F2F8FF;
    padding: 0.417vw;
    width: 2.222vw;
    color: #2D2F32;
    height: 2.222vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dropdown-item:active{
    background: rgba(51,154,235,.1) ;
    color: #46B0E6;
}
.notification-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.042vw;
    align-items: center;
}
#sidebar span.icon:hover{
    color: #1D80D2;
    transition: 0.2s ease;
}
.notification-box{
    display: flex;
    border-top: 0.069vw solid rgba(216, 219, 232, 0.3);
    padding: 1.181vw 0 0.903vw;
}
.notification-content .content-header{
    display: flex;
    justify-content: space-between;

}
.notification-content{
    margin-left: 0.833vw;
    width: 100%;
}
.notification-body .notification-box:last-child{
    padding-bottom: 0;
}
.modal .delete-icon{
    background: rgba(157, 64, 75, 0.1);
    width: 6.667vw;
    height: 6.667vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.delete-modal h1{
    font-weight: 500;
    font-size: 1.250vw;
    line-height: 1.806vw;
    text-align: center;
    color: #2D2F32;
    margin: 1.667vw 0 1.042vw;
}
.delete-modal h3{
    margin-bottom: 0;
}
.delete-modal h3 b{
    font-weight: 500;
    font-family: 'Archivo';

}
.delete-modal h3,.delete-modal p{
    font-weight: 500;
    font-size: 1.111vw;
    line-height: 1.667vw;
    text-align: center;
    color: #585858;
}
.delete-modal p{
    font-weight: 500;
    margin:0;

}
.delete-modal .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.delete-modal .modal-footer{
    justify-content: center;
    margin-top: 2vw;
}
.only-for-mob{
    display: none;
}
.user-management.ivr-management .row.user_table{
    padding-top: 1.042vw;
    padding-bottom: 1.042vw;
}
.published{
    background: #E6F9ED !important;
    color: rgba(3, 192, 74, 1) !important;
}
.save, .saved{
    color: #EF9829 !important;
    background: #FDF4E7 !important;
}
.actionIcons.more-icon-con .icon-more-vertical{
    transform: rotate(0deg);
}
.client-holder span{
    font-weight: 500;
    font-size: 0.903vw;
    line-height: 1.111vw;
    color: #2D2F32;
    opacity: 0.7;
    padding-left: 0.5vw;
    padding-top: 0.278vw;

}
.client-holder img{
    max-width: 1.944vw;
    width: 1.944vw;
}
.client-holder{
    display: flex;
    align-items: center;
    max-width: 33.3%;
    width: 33.3%;
}
.clients-list .client-holder:not(:nth-child(1),:nth-child(2),:nth-child(3)){
    margin-top: 1.667vw;

}
.clients-list{
    display: flex;
    flex-wrap: wrap;
}
#clientData .modal-dialog {
    max-width: 42.500vw;
    margin: 1.75rem auto;
}

.upload-file-design {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.222vw;
}

.upload-file-design > input[type='file'] {
    display: none
}

.upload-file-design > label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    user-select: none;
    background: #FFFFFF;
    border: 0.069vw dashed #ABABAB;
    border-radius: 0.278vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.042vw 1.319vw;
    width: 100%;
}
.upload-file-design .upload-plus-circle{
    background: #F2F2F2;
    height: 2.778vw;
    width: 2.778vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    color: #747474;
    border-radius: 50%;
    margin-bottom: 0.694vw;
    font-family: 'Archivo';
}
.upload-file-design .upload-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upload-content .upload-title{
    font-weight: 500;
    font-size: 0.972vw;
    line-height: 1.250vw;
    color: #2D2F32;
    padding-bottom: 0.278vw;
}
.upload-content .upload-description{
    font-style: normal;
    font-weight: 400;
    font-size: 0.833vw;
    line-height: 0.972vw;
    color: #767676;


}
.forms-field{
    margin-bottom: 1.389vw;
}
.role-btns{
    display: flex;
    align-items: center;
    margin-right: 3.472vw
}
.role-btns input{
    width: 1.389vw;
    height: 1.389vw !important;
}
.role-btns span{
    padding-left: 0.833vw;
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #2D2F32;
    padding-top: 0.308vw;
    display: flex;
    align-items: center;
    height: 20px;

}
.version-grid-con .more-icon-con {
    left: -1.250vw;
}
.role-container{
    margin-bottom: 1.042vw !important;
}
.roles-list{
    display: flex;
    flex-wrap: wrap;
}
.container-role [type="radio"]:checked,
.container-role [type="radio"]:not(:checked) {
    position: absolute;
    display: none;
}

.container-role [type="radio"]:checked + span,
.container-role [type="radio"]:not(:checked) + span
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
}
.container-role [type="radio"]:checked + span:before{
    border: 0.069vw solid rgba(51, 154, 235, 1) !important;

}
.container-role [type="radio"]:checked + span:before,
.container-role [type="radio"]:not(:checked) + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 0.069vw solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.container-role [type="radio"]:checked + span:after,
.container-role [type="radio"]:not(:checked) + span:after {
    content: '';
    width: 14px;
    height: 14px;
    background: rgba(70, 176, 230, 1);
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.container-role [type="radio"]:not(:checked) + span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.container-role [type="radio"]:checked + span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


/*Checkbox style*/

.container-role [type="checkbox"]:checked,
.container-role [type="checkbox"]:not(:checked) {
    position: absolute;
    display: none;
}
.container-role [type="checkbox"]:checked + span,
.container-role [type="checkbox"]:not(:checked) + span
{
    position: relative;
    padding-left: 1.944vw;
    cursor: pointer;
    display: inline-block;
}
.container-role [type="checkbox"]:checked + span:before{
    border: 0.069vw solid rgba(51, 154, 235, 1) !important;
    background: rgba(70, 176, 230, 1) !important;
}
.container-role [type="checkbox"]:checked + span:before,
.container-role [type="checkbox"]:not(:checked) + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.389vw;
    height: 1.389vw;
    border: 0.069vw solid #ddd;
    background: #fff;
    border-radius: 0.278vw;
}
.container-role [type="checkbox"]:checked + span:after,
.container-role [type="checkbox"]:not(:checked) + span:after {
    content: '';
    width: 0.417vw;
    height: 0.833vw;
    transform: rotate(45deg) !important;
    border-bottom: 0.139vw solid #fff;
    border-right: 0.139vw solid #fff;
    position: absolute;
    top: 0.139vw;
    left: 0.486vw;
    transition: all 0.2s ease;
}
.container-role [type="checkbox"]:not(:checked) + span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.container-role [type="checkbox"]:checked + span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.clients-btns{
    display: none;
}
.client-role-list{
    justify-content: space-between;
}
.message-alert{
    background: rgba(16, 136, 94, 1);
    box-shadow: 0 0.278vw 0.278vw rgba(0, 0, 0, 0.25);
    border-radius: 0.417vw;
    color: #fff;
    padding: 1.389vw;
    display: flex;
    font-weight: 400;
    font-size: 1.111vw;
    line-height: 1.389vw;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 3.125vw;
    bottom: 2.361vw;
    opacity: 0;
    visibility: hidden;
    transition: .5s ease-in ;

}

.show-message{
  visibility: visible;
    opacity: 1;


}
@-webkit-keyframes fadeinout {
    to { opacity: 0;
        visibility: hidden;   animation-duration: 2s; }
    from{ opacity: 1; visibility: visible ;  }
}

@keyframes fadeinout {
    to { opacity: 0;
        visibility: hidden;   animation-duration: 2s; }
    from{ opacity: 1; visibility: visible ;  }
}
.message-alert .message-text{
    padding: 0.278vw 1.806vw 0 0.833vw;
}
.message-alert .icon-Union1{
    font-size: 0.833vw;
}
.message-alert .icon-check-circle{
    font-size: 1.389vw;
}
.time-with-icon .grid-reg-text time{
    padding-left: 0.694vw;
    position: relative;
    top: 0.069vw;
}
.user_container.bordered-table{
    border: 0.069vw solid #E8E8E8;
    border-radius: 0.347vw;
    margin-top: 1.944vw;
}
.bordered-table{
    margin-top: 2.431vw;
    border: 0.069vw solid #E8E8E8;
    border-radius: 0.347vw;
}
.actionIcons .icon-edit-22{
    font-size: 1.319vw;

}
.actionIcons .icon-trash{
    font-size: 1.319vw;

}
.searchbutton .icon-Combined-Shape{
    font-size: 1.111vw;
    position: relative;
    top: -0.069vw;
}
.dropdown-item.option-has-icon p{
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #2D2F32;
    font-family: 'Archivo';
margin-bottom: 0;
    padding: 0.139vw 0 0 ;
}
.dropdown-menu .dropdown-item.option-has-icon[title = "Delete"] p{
    padding-top: 0.139vw;
}
.more-icon-con .dropdown-item {
    height: 2.361vw;
    border-radius: 0.278vw;
    padding: 0 0.972vw;
}
.dropdown-item.option-has-icon span.icon-trash{
    margin-top: -0.208vw;
}
.dropdown-item.option-has-icon span.icon-trash:before{
    color: rgba(41, 45, 50, 1);
    transition: 0.2s ease;
}
.dropdown-item.option-has-icon:hover span.icon-trash:before{
    color: #46B0E6;
    transition: 0.2s ease;
}
.schedule-icon {
    background: rgba(51, 154, 235, 0.1);
    border: 0.069vw solid rgba(51, 154, 235, 1);
    border-radius: 50%;
    height: 6.667vw;
    width: 6.667vw;
    color: rgba(51, 154, 235, 1);
    font-size: 2.361vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-with-bg{
    padding: 1.042vw 1.667vw;
    background: #F6F6F6;
    border-radius: 0.278vw;
    margin: 1.667vw 0 0;
}
.text-with-bg:last-child{
    margin-top: 0.833vw;
    margin-bottom: 1.389vw;
}
.text-with-bg h4{
    font-weight: 400;
    font-size: 1.111vw;
    line-height: 1.319vw;
    color: #585858;
    margin-bottom: 0;
    letter-spacing: 0.014vw;
}
.modal-footer.schedule-footer{
    margin-top: 0.556vw;
}
.text-with-bg h4 span{
    font-weight: 500;
    color: #2D2F32;
}
.schedule-changes h1{
    font-weight: 700;
    font-size: 1.250vw;
    line-height: 1.528vw;
    color: #2D2F32;
}
.schedule-changes h3{
    font-weight: 400;
    font-size: 0.972vw;
    line-height: 1.389vw;
    color: #585858;
}
.schedule-modal{
    display: none;
}
.publish-icon{
    background: rgba(53, 193, 145, 0.1);
    color: rgba(53, 193, 145, 1);
    border: 0;

}
.row.user_table .role-type .ivr-grid-status {
    font-size: 0.556vw;
    padding: 0.486vw 1.111vw 0.278vw;
}
@keyframes slide-down {
    0% {
        visibility: hidden;
        height: 0;
    }

    95% {
        visibility: visible;
        height: auto;
    }

    /* Set height to 'auto' after animation for spacing showing form-invalid feedback message */
    100% {
        visibility: visible;
        height: auto;
    }
}
@keyframes slide-up {
    from {
        visibility: visible;
        height: 0;
    }

    to {
        visibility: hidden;
        height: 0;
    }
}

.not-visible{
    visibility: hidden;
    height: 0;
    opacity: 0;
    padding: 0 !important;
    margin: 0;
    overflow: hidden;
}
.visible-class{
    opacity: 1;
    visibility: visible;
    height: auto;

}
.row.user_table.roles_table .ivr-grid-status {
    font-size: 0.556vw;
    padding: 0.486vw 1.111vw 0.278vw;


}
.ivr-modal .roles-list-modal label {
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #686573;
    margin-bottom: 1.111vw;
    margin-right: 0;
    width: 33.3%;
}
.roles-list-modal .client-role-list {
    justify-content: flex-start;
}
/*calendar css*/
.ivr-calendar{
    padding: 0.694vw 0 ;
    width: 100%;
    border: 0 !important;
    box-shadow: 0 0.235vw 0.470vw rgba(0, 0, 0, 0.07);
    border-radius: 0.235vw;
}
.ivr-calendar .react-datepicker__header{
    background: #fff;
    border: 0;
}
.ivr-calendar .react-datepicker__navigation{
    top: 1.250vw;
}
.ivr-calendar .react-datepicker__day-name{
    font-family: 'Archivo';
    font-size: 0.647vw;
    line-height: 0.764vw;
    text-align: center;
    color: #585858;
    opacity: 0.65;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 50%;
    color: #fff;
    background: #46B0E6;
    font-weight: 500;
    font-size: 0.833vw;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    border-radius: 50% !important;
    color: #fff;
    background: #46B0E6 !important;
    font-weight: 500;
    font-size: 0.833vw;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    border-radius: 50% !important;
    background-color: #f0f0f0;
}
.react-datepicker__day-names {
    margin-bottom: -0.556vw;
    margin-top: 0.556vw;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 50% !important;
    color: #fff;
    background: #46B0E6 !important;
    font-weight: 500;
    font-size: 0.833vw;
}
.react-datepicker__month-container {
    width: 100%;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #767676 !important;
    border-width: 0.104vw 0.069vw 0 0 !important;

}
.react-datepicker__navigation--previous {
    left: 0.833vw !important;
}
.react-datepicker__navigation--next {
    right: 0.833vw !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 50% !important;
    color: #fff;
    background: #46B0E6 !important;
    font-weight: 500;
    font-size: 0.833vw;
}
.react-datepicker__input-container input {
    background: #F6F6F6;
    border-radius: 0.278vw;
    font-weight: 500;
    font-size: 0.903vw;
    padding-bottom: 0.069vw;
    height: 2.778vw;
    position: relative;
    width: 100%;
    color: #2D2F32;
    line-height: 1.111vw;
    padding-left: 1.111vw;
    letter-spacing: 0.028vw;
    border: 0;
}
.btn.avatar-name.new-notification{
    position: relative;
    top: -0.069vw;
}
.actionBtns .icon-edit-22{
    background: transparent;
    padding: 0.208vw;
    border-radius: 0.278vw;
}
.actionBtns .icon-edit-22:hover{
    background: #339AEB1A;
    padding: 0.208vw;
    border-radius: 0.278vw;
    transition: 0.2s ease;

}
.actionBtns .icon-trash{
    background: transparent;
    padding: 0.208vw;
    border-radius: 0.278vw;
}
.actionBtns .icon-trash:hover{
    background: #9D404B1A;
    padding: 0.208vw;
    transition: 0.2s ease;

    border-radius: 0.278vw;
}
.actionBtns .icon-trash:hover:before{
    color: rgba(157, 64, 75, 1);
    transition: 0.2s ease;

}
.actionBtns .icon-edit-22:hover:before{
    color: rgba(51, 154, 235, 1);
    transition: 0.2s ease;
}
.btn.avatar-name.new-notification::before{
    position: absolute;
    content: "";
    height: 0.833vw;
    width: 0.833vw;
    background: #F79E1B;
    border: 0.174vw solid #FFF;
    top: -0.347vw;
    right: 0;
    border-radius: 50%;
    z-index: 5;
    left: 1.042vw;
}
.react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 0.694vw;
    width: 97% !important;
}
.header-notification.icon-Notification{
    position: relative;
    top: 0.208vw;
}
.header-notification.icon-Notification::before{
    font-size: 2.083vw;
    font-weight: normal;
}
.roles-type-grid {
    flex: 0 0 10%;
    max-width: 10%;
}
.clients_icon a img {
    min-height: 1.944vw;
    min-width: 1.944vw;
   height: 1.944vw;
   width: 1.944vw;
}
.user_image_wrapper img{
    width: 100%;
}
.grid-reg-text img{
    width: 1.111vw;
}



@media (max-width: 767px){
    @supports (-webkit-touch-callout: none) {
        .rows-con , .user_management , .user-management {
            padding-bottom: 5.556vw !important;
        }
    }

}
@media (max-width : 992px) and (min-width: 767px){
    .actionIcons .dropdown button {
        left: 0 !important;
        top: 1.458vw;
    }
}

.user_management .container-fluid .row.user_table .roles-type-grid {
    position: relative;
}
/*end calender css*/
@media(min-width: 1200px){
    .tab-content{
        max-height: 90vh;
        overflow: scroll;
        margin-top: 1.042vw;
        scrollbar-width: none;

    }
    .tab-content div:first-child .sub-heading{
        margin-top: 1.02vw;
    }
    .more-icon-con > .dropdown {
        left: 1.736vw;
        top: -0.764vw;
    }
    .contact-no{
        flex: 0 0 10.5%;
        max-width: 10.5%;
    }
    .role-type{
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
    .actions-grid{
        flex: 0 0 6.5%;
        max-width: 6.5%;
    }
    .status-grid{
        flex: 0 0 5%;
        max-width: 5%;
    }
    .last-activity-grid {
        flex: 0 0 17.5%;
        max-width: 17.5%;
    }
    .actions-grid .actionIcons a {
        margin-right: 1.042vw;
    }
    .actions-grid .actionIcons:first-child {
        margin-left: 0.347vw;
    }
    .user-grid{
        flex: 0 0 17.666667%;
        max-width: 17.666667%;
    }
    .status-grid .gridHeadText{
        padding-left: 0;
    }
}
.user_management .row.user_table .centered_content {
    justify-content: flex-start !important;
}
.react-datepicker__input-container::after{
    position: absolute;
    content: "\900d";
    font-family: "icomoon";
    right: 0.972vw;
    top: 0.347vw;
    color: rgba(136, 136, 136, 1);
}
.main-header header{
    display: none;
}
.sidebar-header-bar header{
    display: block;
}
.sidebar-header-bar .avatarWrapper {
    text-align: left;
    position: relative;
    display: block;
    left: 0;
    z-index: 55;
    transform: none;
}
.sidebar-nav{
    display: flex;
    flex-direction: column;
    align-self: s;
    justify-content: space-between;
    height: calc(100% - 5.139vw);
}
.sidebar-header-bar::before {
    content: "";
    position: absolute;
    height: 0.09vw;
    width: 100%;
    background: #767676;
    left: -0.278vw;
    top: 4.375vw;
    opacity: 0.12;
}
.sidebar-header-bar{
    padding:0 ;
    position: relative;
    top: -1.736vw;
    left: 0.278vw;
}
.sidebar-header-bar .avatarWrapper .dropdown {
    left: 0;
    margin-left: 0;
    display: flex;
    align-items: center;
}
.sidebar-header-bar .btn.avatar-name.new-notification {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
}

.avatar-img img{
    max-width: 2.778vw;
}
.avatar-dropdown-toggle img{
    width: 2.778vw;
}
.ivr-grid-con:not(.version-grid-con) > .row:not(.ivr-grid-heads)::before {

}
.user_management,.user-management{
    box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.01);
    max-height: 82vh;
    overflow: auto;
    scrollbar-width: none;
}
.gridHeadText{
    font-size: 0.833vw;
    line-height: 1.111vw;
    font-family: 'Archivo';
    color: #686573;
    letter-spacing: 0.014vw;
}
.grid-date-col {
    font-size: 0.903vw;
    color: #686573;
    /*opacity: 0.8;*/
    font-family: 'Archivo';
    margin-top: -0.139vw;
}

.ivr-name-col,
.version-name-col,
.grid-date-col {
    padding-top: 0.347vw;
}
.ivr-grid-con > .row:not(.ivr-grid-heads) + .row {
    margin-top: 1.389vw;
}

/*---Switch---*/
.custom-switch .custom-control-label::before {
    height: 1.389vw;width: 2.361vw;
    border-color:rgba(51, 154, 235, 0.1); background-color: rgba(51, 154, 235, 0.1);
}
.custom-switch .custom-control-label::after {
    height: 0.972vw;width: 0.972vw;
    top: calc(.25rem + 0.208vw);
    left: calc(-2.25rem + 0.208vw);
    background-color: #747690;
}
.custom-switch .custom-control-label::before,
.custom-switch .custom-control-label::after
{
    border-radius: 0.694vw;
}
.custom-switch .custom-control-label::before {
    background: #EAEBED;border-color: #EAEBED;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: translateX(0.944vw);
    transform: translateX(0.944vw);
}

.custom-control-label::before {
    border: rgba(51, 154, 235, 0.1);
}
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: rgba(51, 154, 235, 0.1);
    background-color: rgba(51, 154, 235, 0.1);
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #46B0E6;
}
/*---End Switch---*/

.grid-date-col-icon {
    vertical-align: text-bottom;
    margin-right: 0.486vw;
}
.ivr-grid-status.saved {
    border-color: transparent;
    color: #F79E1B;
    background: #FFF2E0;
    border-radius: 1.111vw;
}
.ivr-grid-status {
    display: inline-block;
    margin-top: 0;
    height: auto;
    border: none;
    text-align: center;
    color: #10885E;
    text-transform: uppercase;
    font-size: 0.7vw;
    font-family: 'Archivo';
    letter-spacing: 0.069vw;
    background: #E5FCF4;
    border-radius: 1.1vw;
    padding: 0.556vw 1.181vw 0.278vw;
    line-height: 0.833vw;
}
.notification-body .img-wrapper{
    position: relative;
}
.notification-body .img-wrapper.new:before{
    content: "";
    background: #F79E1B;
    height: 0.417vw;
    width: 0.417vw;
    border-radius: 50%;
    position: absolute;
    top: -0.069vw;
    left: 0.278vw;
}
.grid-reg-title{
    font-weight: 500;
    font-size: 0.972vw;
    line-height: 1.111vw;
    color: #000;
    font-family: 'Archivo';
    padding-left: 0.556vw;
    letter-spacing: 0.021vw;
}
.main-wrapper.login-wrapper .copyrights-p{
    display: none;
}
.grid-reg-text {
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.111vw;
    color: rgba(45, 47, 50, 0.7);
    font-family: 'Archivo';
}
.grid-status-column {
    text-indent: 0.556vw;
}
.grid-instance-column,
.grid-instance-head-column,
.col.col-action,
.grid-action-column {
    padding: 0 1.042vw 0.486vw 1.042vw;
}
.col-action > span.grid-drop-caret {
    margin-top: 0.278vw;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.ivr-grid-con > .row .w-100 {
    margin: 0.903vw 0 1.667vw;
}

.sup-lang-wrap {
    display: flex;
}
.detail-label {
    margin-bottom: 0.278vw;
    color: #3D3D3D;
    font-family: 'Archivo';
    font-size: 0.833vw;
    line-height: 1.111vw;
}
.detail-desc p, .detail-desc {
    font-family: 'Archivo';
    font-size: 0.833vw;margin: 0;
    line-height: 1.319vw;
    color: rgba(45, 47, 50, 0.7);
}
.detail-icon {
    margin-right: 0.764vw;
    padding-top: 0.486vw;
}
.intello-msg-col .detail-desc {
    padding-right: 3.958vw;
}
.ivr-grid-con > .row:not(.ivr-grid-heads) > .row {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.grid-drop-caret.grid-drop-caret-down {
    transform: rotate(0deg) !important;
    cursor: pointer;
}

.grid-instance-column, .grid-instance-head-column, .grid-action-column {
    padding: 0 1.042vw 0 1.042vw;
}
.col-action{
    padding: 0 1.042vw 0.833vw 0 !important;
}
.col.col-xl-3.grid-instance-column > img {
    vertical-align: middle;
    display: inline-block;
    margin-right: 0.278vw;
    min-width: 2.222vw;
    margin-left: 1.736vw;

}
.desktop-menu-ham{
    cursor: pointer;
}
.icon.icon-Menu {
    font-size: 1.597vw;
}
.responsiveTitle, .responsive-menu-ham{
    display: none;
}
.desktop-menu-ham > span.nav-text {
    padding-top: 0;
    vertical-align: text-bottom;
}
.dropdown-toggle.filter-dropdown:focus , .dropdown-toggle.filter-dropdown:hover{
    outline: 0;
}
/*modal*/

#addIVR {
}
#addIVRLabel {
    font-weight: 700;
    font-size: 1.250vw;
    line-height: 1.528vw;
    color: #2D2F32;
}
.modal-header {
    border: none;
}
.modal-content {
    padding: 2.778vw 2.778vw 2.083vw;
}
.modal-dialog{
    min-width: 42.500vw;
}
.modal-header {
    padding: 0;
    margin: 0 0 1.2vw;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;

}
#addIVRLabel small {
    font-weight: 400;
    font-size: 0.972vw;
    line-height: 1.389vw;
    color: #585858;
    display: block;
    margin-top: 0.7vw;
}
.modal-footer {
    padding: 0;margin-top: 1.083vw;
    border: 0;
    position: relative;
    top: -0.278vw;
    left: 0.139vw;
}

.sidebarLogoWrapper .logo-link img{
    width: 3.33vw;
}
.ivr-modal .btn {
    width: 8.333vw;
    height: 2.778vw;
    padding: 0.278vw 0 0;
    border-radius: 0.278vw;
    border: none;
    font-weight: 700;
    font-size: 0.833vw;
    line-height: 1.111vw;
    text-align: center;
}
.ivr-modal .btn.btn-secondary {
    background: #fff;
    border-radius: 0.278vw;
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.111vw;
    text-align: center;
    color: #2D2F32;
    border: 0.069vw solid rgba(45, 47, 50, 0.5);
}
.ivr-modal .btn.btn-primary {
    background: #46B0E6;
    color: #FFFFFF;
    transition: all 0.3s ease-in-out;
}
.modal-body {
    padding: 0 0 0;
}
.ivr-modal label {
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #686573;
    margin-bottom: 0.347vw;
}

.ivr-modal select.form-control {
    padding-top: 0.694vw;
    font-weight: 500;
    font-size: 0.903vw;
    line-height: 1.111vw;
    color: #2D2F32;
    border-radius: 0.278vw;
    border: none;
    height: 2.778vw;
    font-family: 'Archivo';
    background: #F6F6F6 url('../images/caret_drop.svg') no-repeat calc( 100% - 1.181vw) center;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.ivr-modal .modal-dialog.modal-lg {

    border-radius: 0.278vw;
    border: none;
    max-width: 42.500vw;
}
.ivr-modal .form-row + .form-row {
    margin-top: 1.458vw !important;
}
.ivr-modal .mand-field {
    font-family: 'Archivo';
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #CE3A3A;
}
.textAreaField textarea {
    background: #F6F6F6;
    font-family: 'Archivo';
    border-radius: 0.278vw;
    border: none;
    padding: 0.833vw;
    font-size: 0.903vw;
    line-height: 1.111vw;
    resize: none;
    min-height: 5.556vw;
    border:0.069vw solid transparent;
}
.textAreaField textarea::placeholder {
    font-weight: normal;
    font-size: 0.903vw;
    line-height: 1.111vw;
    color: #2D2F32;
    opacity: 0.6;
}
.ivr-modal .btn.btn-secondary:hover,
.ivr-modal .btn.btn-secondary:focus {
    background-color: #46B0E6;
    border-color: #46B0E6;
    color: #fff;
}
.ivr-modal .btn.btn-primary:hover,
.ivr-modal .btn.btn-primary:focus {
    background-color: rgba(22, 134, 223, 1);
    color: #fff;
}
.modalSwitchWrap > label {
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #686573;
    display: inline-block;
    vertical-align: baseline;
    margin-right: 0.556vw;
    margin-top: 0;
}
.modalSwitchWrap > .custom-control.custom-switch {
    display: inline;
    vertical-align: baseline;
    top: -0.208vw;
    right: -0.208vw;
}
.ivr-modal .form-row > .col {
    margin-right: 0.347vw;
}
.ivr-modal .form-row > .col + .col {
    margin-left: 0.347vw;
}
.ivr-modal .btn + .btn {
    margin-left: 1.597vw;
}
/*modal end*/

/*Version Management*/
.sub-heading {
    font-family: 'Archivo';
    font-size: 1.250vw;
    line-height: 1.528vw;
    color: #2D2F32;
    margin: 1.7vw 0 0.972vw;
    display: none;
}

.one-box {
    background: #FFFFFF;
    /*box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.07);*/
    border-radius: 0.278vw;
    padding: 1.458vw 1.181vw 1.181vw 1.667vw;
    font-weight: 500;
    font-size: 1.042vw;
    line-height: 1.528vw;
    color: #686573;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}
.call-icon-con {
    background: rgba(29, 128, 210, 0.09);
    border-radius: 1.111vw;
    width: 2vw;
    height: 2vw;
    color: #fff;
    text-align: center;
    padding: 0.694vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-call{

    color: #46B0E6;
    position: relative;
}
.icon-call:before{
    font-size: 0.97vw;
}
.call-type-text {
    font-weight: 500;
    font-size: 0.972vw;
    line-height: 1.111vw;
    color: #686573;
    font-family: 'Archivo';
    margin-left: 0.486vw;

}
.more-icon-con {
    text-align: right;
    position: relative;
    left: -0.764vw;
    top: -0.208vw;
}
.one-box > .row {
    align-items: center;
}

.icon-more-vertical {
    vertical-align: middle;
    color: #8D8293;

}
.fav-icon-con span {
    font-size: 1.736vw;
    line-height: 1;
    cursor: pointer;
    margin-right: 0.278vw;
}
.fav-icon-con span.icon-Star_1 {
    color: #8D8293;
}
.fav-icon-con span.icon-Star_03 {
    color: #F79E1B;
}
.fav-icon-con {
    text-align: right;
    padding-top: 1.250vw;
}
.box-last-modified {
    font-size: 0.833vw;
    line-height: 1.111vw;
    color: #494949;
    display: block;
    font-family: 'Archivo';
    font-weight: 400;
}
.box-last-modified-date {
    font-size: 0.833vw;
    line-height: 1.111vw;
    font-family: 'Archivo';
    color: #4D4D4D;

}
.date-fav-wrapper {
}
.box-heading {
    font-size: 1.25vw;
    line-height: 1.6vw;
    color: #2D2F32;
    font-family: 'Archivo';
    margin-top: 1.250vw;
    margin-bottom: 0.694vw;
    max-width: 90%;
}
.box-text {
    font-size: 1vw;
    line-height: 1.5vw;
    color: #767676;
    font-family: 'Archivo';
    margin-bottom: 2.917vw;
    padding-right: 4.306vw;
}

.filtersCon {
    margin-top: 0.625vw;
}
.filtersCon {
    margin-right: -1.042vw;
}
.version-grid-con.ivr-grid-con > .row:not(.filtersCon) {
    padding-left: 1.042vw;
    padding-right: 1.042vw;
}
.more-icon-con > .dropdown {
    display: inline-block;
    cursor: pointer;
}

.sub-heading:nth-child(3) {
    margin: 2.431vw 0 1.389vw;
}
/*Version Management End*/

/*dropdown*/
.dropdown-menu {
    background: #FFFFFF;
    box-shadow: 0 -0.069vw 0.278vw rgba(0, 0, 0, 0.07), 0 0.278vw 0.556vw rgba(0, 0, 0, 0.07);
    border-radius: 0.278vw;
    border: none;
    padding: 1.042vw 0.347vw 1.042vw;
}
.more-icon-con > .dropdown {
    display: inline-block;
    cursor: pointer;
}


.dropdown-item + .dropdown-item {
    margin-top: 0.208vw;
}
.dropdown-item {
    font-size: 0.903vw;
    line-height: 0.694vw;
    color: #2D2F32;
    height: 2.639vw;
    padding-top: 0.486vw;
    border-radius: 0.278vw;
    padding-left: 1.042vw;
    font-family: 'Archivo';
}
.tooltip {
    display: inline;
    position: relative;
    opacity: 1;
}
.tooltip:hover:after{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    background: #444;
    border-radius: 0.556vw;
    color: #fff;
    content: attr(title);
    margin: -5.694vw auto 0;
    font-size: 1.111vw;
    padding: 0.903vw;
    width: 15.278vw;
}
.tooltip:hover:before{
    border: solid;
    border-color: #444 transparent;
    border-width: 0.833vw 0.417vw 0 0.417vw;
    content: "";
    left: 45%;
    bottom: 2.083vw;
    position: absolute;
}
.dropdown-item:hover, .dropdown-item:focus {
    background: #F9F9F9;
    color: #46B0E6;
}
.dropdown-item:hover span:before, .dropdown-item:focus span:before{
    color: #46B0E6;
    transition: 0.2s ease;

}
.avatarWrapper .dropdown-items a span.icon{
    font-size: 1.111vw;
}
.avatarWrapper .dropdown-items a span:last-child{
    padding: 0.208vw 0 0 0;
}

.dropdown-item:hover p, .dropdown-item:focus p {
    transition: all 0.3s;
    color: #46B0E6;
}
.dropdown-item.option-has-icon > span {
    margin-right: 0.694vw;
    font-size: 1.319vw;
    line-height: 1.2;
}
.more-icon-con > .dropdown .dropdown-menu {
    top: 0.347vw !important;
}

.dropdown-item + .dropdown-item {
    margin-top: 0;
}
#dropdownMenuButton {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    left: -0.486vw;
    position: relative;
    top: 2.431vw;
}
.header-notification span span{
    font-family: 'icomoon' !important;
}
.header-notification span{
    font-size: 1.806vw;

}

.icon-more-vertical {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0.347vw 0.694vw 0.347vw;
    font-size: 0.97vw;
    transform: rotate(90deg);
}
a:focus , a:focus-visible , a:focus-within ,.user-management.ivr-management:focus ,.user-management.ivr-management:focus-visible ,.user-management.ivr-management:focus-within {
    outline: 0;
    border: 0;
}
/*dropdown end*/

/*one box flexing*/
.one-box {
    flex: 0 0 25%;
    max-width: calc(25% - 1.319vw);
    margin-right: 1.667vw;
    margin-bottom: 1.667vw; border: 0.069vw solid #F1F1F1;
    border-radius: 0.556vw;
}
.one-box:nth-child(n+4){
    margin-top: 0;
}
.rows-con {
    display: flex;flex-wrap: wrap;
    justify-content: flex-start;

}
.rows-con + .rows-con {
    margin-top: 1.389vw;
}
.rows-con .one-box:nth-child(5n+5) {
    margin-right: 0;
}
/*one box flexing end*/

/*IVR QA Fixes*/
.wrapper {
    position: relative;
}
.menu-backdrop {
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
}
.superadmin{
    background: rgba(225, 220, 255, 1) !important;
}
.admin{

    background: rgba(220, 240, 255, 1) !important;
}
.customer{
    background: rgba(226, 254, 249, 1) !important;
}
.btn-primary:hover , .btn-blue:hover , .btn-primary:focus , .btn-blue:focus {
    background-color: #2B9CD6 !important ;
}

.select2-selection.select2-selection--single {
    width: 100%;
}
.tab-content:focus-within , .tab-content:focus{
    outline: 0;
    border: 0;
}
.avatar-sidebar{
    position: absolute;
    left: 2.986vw;
    opacity: 0;
    transition: 0.5s ease;
top: 0;
    visibility: hidden;
    text-align: left;
    margin-left: 0.556vw;
}
.opacity-hidden{
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease;
    width: 0;
}
.opacity-shown{
    opacity: 1;
    visibility: visible;
    transition: 0.5s ease;
    width: auto;

}
.sidebar-header-bar .btn.avatar-name.new-notification:focus{
    box-shadow: none;
}
.cust-padding{
    padding-left: 1.181vw;
}
.show-avatar-sidebar{
    opacity: 1;
    visibility: visible;
    transition: 0.5s ease;
}
.avatar-sidebar h1{
    font-weight: 400;
    font-size: 0.972vw;
    line-height: 1.250vw;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
}
.avatar-sidebar a{
    font-weight: 300;
    font-size: 0.833vw;
    line-height: 1.250vw;
    color: rgba(0, 0, 0, 0.46);
}
.select2-selection.select2-selection--single {
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #2D2F32;
    font-family: 'Archivo';
    min-width: 14.306vw;
    height: 2.778vw;
    background: #DDE6F3;
    border-radius: 0.278vw;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.069vw;
    /*text-overflow: '';*/
    background: #DDE6F3 url('../images/caret_drop.svg') no-repeat calc( 100% - 17px) center;
}
.select2-selection__arrow {
    display: none;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    /* color: #444; */
    /* line-height: 1.944vw; */
    font-weight: 500;
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #2D2F32;
    font-family: 'Archivo';
    /*width: 206px;*/
    height: 2.778vw;
    /* background: #DDE6F3; */
    border-radius: 0.278vw;
    border: none;
    /* -webkit-appearance: none; */
    /* -moz-appearance: none; */
    text-indent: 0.069vw;
    /*text-overflow: '';*/
    /* background: #DDE6F3 url('../images/caret_drop.svg') no-repeat calc( 100% - 17px) center; */
    text-align: left;
    padding-top: 0.972vw;
    padding-left: 0.833vw;
}
.left-wrapper .img-wrapper img {
    width: 100%;
}
.selection {
    display: block;
}
.dropdown-wrapper {
    display: none;
}
.select2-results__option--selectable {
    font-family: 'Archivo';
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #2D2F32;
}
.custom-control-label:hover {
    cursor: pointer;
}
.col-action {
    display: flex;margin-top: -0.694vw;
    align-items: center;
}
.form-control {
    border: 0.069vw solid transparent;
}
.modal .form-control{
    background: #F6F6F6;
    border-radius: 0.278vw;
    font-weight: 500;
    font-size: 0.903vw;
    padding-bottom: 0.069vw;
    height: 2.778vw;
    position: relative;
    color: #2D2F32;
    line-height: 1.111vw;
    padding-left: 1.111vw;
    letter-spacing: 0.028vw;
}
.modal .plus-icon{
    content: "asda";
    position: absolute;
    right: 1.389vw;
    top: 53%;
}

.modal ::placeholder{
    color: rgba(45, 47, 50, 0.6);
}
.form-control:focus {
    box-shadow: none;border: 0.069vw solid #39B7E9;
}
.ivr-modal .select2-selection.select2-selection--single {
    background-color: #F6F6F6;
}
.modal-footer > :not(:last-child) {
    margin-right: 0;
}

.col-action > a.grid-pen-edit {
    margin-top: 0.347vw;
    margin-right: 0.694vw;
}
.select2-results__option {
    padding: 0.417vw 0.417vw 0.278vw;
}
.avatar-name{
    font-weight: 500;
    font-size: 0.903vw;
    line-height: 1.111vw;
    text-align: right;
    color: #686573;
    padding-right: 2.361vw;
}

form.form-wrapper ::placeholder{
    color: #8D8293;
}
/*IVR QA Fixes End*/

@media (max-width: 1440px) and (min-width: 1367px) {
    .col-xl-3.grid-instance-column ,
    .col-xl-3.grid-instance-head-column{        -ms-flex: 0 0 25%;        flex: 0 0 25%;        max-width: 25%;    }

    .col-xl-2.ivr-name-col,
    .col-xl-2.ivr-name-col-head{ -ms-flex: 0 0 15.667%;        flex: 0 0 15.667%;        max-width: 15.667%; }

    .col-xl-2.ivr-version-col-head,
    .col-xl-2.version-name-col {        -ms-flex: 0 0 17.667%;        flex: 0 0 17.667%;        max-width: 17.667%;    }

    .col-xl-2.grid-date-col ,
    .col-xl-2.grid-date-col-head {  -ms-flex: 0 0 17.667%;  flex: 0 0 17.667%;  max-width: 17.667%;    }

    .col-xl-2.grid-status-col ,
    .col-xl-2.grid-status-column {        -ms-flex: 0 0 14.667%;        flex: 0 0 14.667%;        max-width: 14.667%;    }
    .actionBtns{
        width: 6.944vw;
    }
    .user-grid {
        flex: 0 0 19.667%;
        max-width: 19.667%;
        padding-left: 0.347vw;
        padding-right: 0.347vw;
    }
    .col-xl-3.intello-msg{
        -ms-flex: 0 0 24%;
        flex: 0 0 24%;
        max-width: 24%;
    }
    .contact-no {
        flex: 0 0 13.5%;
        max-width: 13.5%;
    }
    .last-activity-grid {
        flex: 0 0 20.5%;
        max-width: 20.5%;
    }
}
.col-xl-2.clients-cust{
    flex: 0 0 15.667%;
    max-width: 15.667%;
}
.user-management.ivr-management .row.user_table .centered_content {
    justify-content: flex-start !important;
}

@media (max-width: 1366px) {
    .select2-selection.select2-selection--single {
        min-width: 0 !important;
    }
    .actionIcons a {
        margin-right: 0.417vw !important;
    }

    #sidebar ul li + li {
        margin-top: 1.806vw;
    }
    .user_management, .user-management {
        max-height: 75vh;
    }
    .avatarWrapper .dropdown-menu.show {
         top: 2.986vw !important;
     }


}
@media (max-width: 1280px) {
    #sidebar.collapsed ul li a, .icon.icon-Menu {
        /*font-size: 1.15em;*/
    }
    .ivr-grid-con > .row:not(.ivr-grid-heads), .ivr-grid-con > .row{
        padding-left: 1.389vw;
        padding-right: 1.389vw;
    }
    .col-action > span {
        margin: 0 0 0 0.347vw;
    }
}

@media (max-width: 1199px) {
    .ivr-grid-con > .row:not(.ivr-grid-heads) > .row > div.col-12,
    .ivr-grid-con > .row:not(.ivr-grid-heads) > .row > div.col {
        margin: 0.347vw 0;
    }

    .searchField, #ivrName, #clientName, #addIVRBtn, #addVersionBtn,
    .pageFilters .chosen-container-single .chosen-single
    {
        max-width: 100%;
        height: 2.778vw;
    }
    .searchFieldCon, .ivrNameCon, .clientNameCon, .addVersionBtnCon, .addIVRBtnCon {
        height: 2.639vw;
    }
}

@media (max-width: 1080px) {
    .col.col-action {
        text-align: right;
    }
    /*.grid-status-col, .grid-status-column {*/
    /*    padding-right: 0;*/
    /*    padding-left: 0;*/
    /*}*/
    .grid-status-column {
        text-indent: 0;
    }
    .grid-action-column {
        text-indent: 0;
    }
}
@media (max-width: 991px) and (min-width: 769px) {
    .col-md-2.col-action {
        padding-left: 6%;
    }
}
@media (max-width: 1024px) {
    /*Header logo QA FIx*/
    .responsive-menu-ham {
        display: inline-block;
        line-height: 1;
        padding: 0;
        margin: 0 0.694vw 0 0;
    }
    .responsiveTitle{
        display: none;
    }


}


.search-body.show .container-fluid{
    max-width: 94%;
    margin: 0 auto 0.694vw;
    box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.07);
}
.search-body.show{
    position: absolute;
    top: 29.7vw;
    left: 0;
    width: 100%;
    max-height: 100%;
    overflow: scroll;
    z-index: 11;
    overflow-x: hidden;
    scrollbar-width: none;
}
.search-body.show .one-box{
    max-width: 94%;
    margin: 0 auto 0.694vw;
    box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.07);
}
.backdrop-filters::before{
    content: "";
    position: absolute;
    background: url("../images/blur-bg.png")no-repeat;
    height: 100%;
    width: 100%;
    z-index: 10;
    top: 0;
    background-size: cover;

}

@media (max-width: 550px) {
    .ivr-modal .form-row > .col + .col{
        margin-left: 0;
    }
    /*QA fix*/
    .intello-msg-col .detail-desc{padding-right: 0;}
    /*QA fix end*/
}
@media (max-width: 575px) {

    .searchFieldCon, .ivrNameCon, .clientNameCon,.addVersionBtnCon, .addIVRBtnCon {
        max-width: 25%;
        width: 25%;
        display: none;
    }

    .ivr-grid-con:not(.version-grid-con) > .row:not(.ivr-grid-heads) {
        padding-left: 0;
        padding-right: 0;
    }
    .ivr-grid-con > .row:not(.ivr-grid-heads) > .row > div.col-12,
    .ivr-grid-con > .row:not(.ivr-grid-heads) > .row > div.col {
        padding: 0;
    }
}


.pageFilters .form-row > .col,  .pageFilters .form-row > [class*="col-"]{padding-left:0.347vw; padding-right:0.347vw;}
.btn-blue-form-header .add-icon{ background:  url('../images/plus-circle.svg') no-repeat center center; display:inline-block; width:1.111vw; height:1.111vw; float:left;}
.btn-blue-form-header{ background-image:none !important; padding-left:1.528vw !important; padding-right:1.667vw;width:auto !important}
.btn-blue-txt {  float: left;  margin-left: 0.694vw;  padding-top: 0.139vw; }

/* Bilal */
.btn-filter-icon {
    width: 1.944vw;
    height: 1.944vw;
    line-height: 0.5;
    padding: 0;
    border: 0.069vw solid #D8DBE8;
    border-radius: 0.278vw;
}

#filterModal .modal-content {
    border: none;
}

.filter-modal .modal-dialog.modal-lg {
    margin: 0;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.07);
}

#addFilterLabel {
    font-weight: 700;
    font-size: 1.111vw;
    line-height: 1.389vw;
    color: #2D2F32;
}

.iconCloseWrap {
    width: 1.250vw;
    height: 1.250vw;
    cursor: pointer;
}

.searchFieldFilter {
    border: 0.069vw solid #D8DBE8;
    background-color: #FFFFFF;
    border-radius: 0.278vw;
    opacity: 0.7;
}

.filter-modal select.form-control {
    font-size: 0.833vw;
    line-height: 1.042vw;
    color: #686573;
    background-color: #FFFFFF;
    border: 0.069vw solid #D8DBE8;
    border-radius: 0.278vw;
}

.filter-modal .btn.btn-secondary {
    border: 0.069vw solid rgba(45, 47, 50, 0.8);
    opacity: 0.7;
}

.filterBox {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.filter-modal .chosen-container-single .chosen-single {
    background-color: #FFFFFF;
    border: 0.069vw solid #D8DBE8;
    border-radius: 0.278vw;
    color: #686573;
    font-size: 0.833vw;
    line-height: 1.042vw;
}

.btn-add-version {
    width: 3.333vw;
    height: 3.333vw;
    background: linear-gradient(0deg, #46B0E6, #46B0E6);
    box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.01);
    border-radius: 2.083vw;

}


.nav-heading {
    font-size: 1.111vw;
    line-height: 1.111vw;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2D2F32;
    opacity: 0.7;
    padding-top: 0.764vw;
    padding-bottom: 0.764vw;
    margin-left: 0.347vw;

}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: transparent;
    font-size: 1.111vw;
    font-weight: 500;
    line-height: 1.111vw;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2D2F32;
    border-bottom: 0.208vw solid #46B0E6;
    border-radius: 0;
    opacity: 1;
    transition: none;
    padding-top: 0.764vw;
    padding-bottom: 0.764vw;
    margin-left: 0.347vw;
}

:not(.collapsed) .sidebar-header{
    display: flex;
}

@media (min-width: 993px) {
    .filterIcon {
        display: none;
    }
    .btn-add-version {
        display: none;
    }
    .row ivr-grid-heads{
        display: flex;
    }
    .tab-content>.tab-pane {
        display: block;
    }

    .tab-content>.active {
        display: block;
    }

    .fade:not(.show) {
        opacity: 1;
    }

    .sub-heading {
        display: block;
    }

    .nav-display {
        display: none;
    }
}

/*=========================GENERIC PLACEHOLDERS ==================*/

.btn-blue {
    border-radius: 0.278vw;
    background-color: #007bff;;
    border:0.069vw solid #007bff;;
    color: #ffffff;
    font-size: 0.833vw;
    padding: 0.64vw 2.153vw 0.64vw;
}

.btn-blue:hover,
.btn-blue:focus {
    background-color: #1581D6;
    color:#fff;;
}

.btn-blue:hover .ico:before,
.btn-blue:focus .ico:before {
    color:#007bff;;
}

.col-p50-wrapper [class^="col-"],
.col-p50-wrapper [class*=" col-"] {
    padding:0 1.736vw;
}

/*================ Switch Toggle Starts ==================*/
.switch-label {
    font-size:0.833vw;
    color:#666666;
}

.switch-field {
    position: relative;
    display: inline-block;
    width: 2.014vw;
    height: 1.111vw;
    font-size: 0.833vw;
    color: #666666;
    margin-right: 0.694vw;
}
.switch-field .switch-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(142,144,170,0.25);
    border:0.069vw solid #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1.111vw;
    width: 1.111vw;
    left:-0.069vw;
    bottom: -0.069vw;
    background-color: #8E90AA;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch-checkbox:checked + .slider {
    background-color: rgba(3,192, 74, .15);
    border-color:rgba(3,192, 74, .15);
}

.switch-checkbox:checked + .slider:before {
    background-color: #03C04A;
}

.switch-checkbox:focus + .slider {
    box-shadow: 0 0 0.069vw #2196F3;
}

.switch-checkbox:focus + .slider {
    box-shadow: none;
}

input:checked + .slider:before {
    -webkit-transform: translateX(0.903vw);
    -ms-transform: translateX(0.903vw);
    transform: translateX(0.903vw);
}

.slider.round {
    border-radius: 0.694vw;
}

.slider.round:before {
    border-radius: 50%;
}

/*=========================SIGNUP PAGES STARTS ==================*/


.full-pg-bg-left {
    left: 0;
}

.full-pg-bg-right {
    right:50%;
}

.full-height-wrapper, .full-height-box .full-height-inner-wrapper {
    height: 100%;
    max-width: 100%;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 100vh;
}

.full-height-inner-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 100vh;
}

.full-height-inner-wrapper .header {
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    align-items: flex-start;
}

.full-height-inner-wrapper .main-wrapper {
    max-width: 100%;
    height: 100%;
    margin-top: auto;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.full-height-inner-wrapper .small-wrapper {
    max-width: 100%;
    -webkit-box-flex: 5;
    -ms-flex: 5 100%;
    flex: 5 100%;
}

.full-height-inner-wrapper .footer {
    max-width: 100%;
    -webkit-box-flex: 1;
    justify-content: center;
    align-items: center;
}

.full-pg-bottom-links {
    position: absolute;
    bottom: 0;
    padding:0 3.472vw 2.083vw 3.472vw;
    color:#ffffff;
}

.full-pg-bottom-links > ul {
    padding: 0;
    margin: 0 0 2.083vw 0;
    list-style: none;
}

.full-pg-bottom-links > ul > li {
    font-size: 0.972vw;
    letter-spacing: 0.035vw;
    line-height: 2.1;
}

.full-pg-bottom-links > ul > li:before {
    content:"\e900";
    font-family: 'icomoon' !important;
    font-size:0.625vw;
    margin-right:1.042vw;
    vertical-align: 0.069vw;
}

.full-pg-bottom-links p {
    font-size: 0.764vw;
    line-height: 1.5;
    letter-spacing: 0.021vw;
}

.header-nav {
    padding: 3.472vw 0 0 0;
}

.logo-desc {
    font-size: 1.667vw;
    color: #111111;
    margin: 0.278vw 0 0 1.111vw;
    display: inline-block;
    font-family: 'Archivo';
    vertical-align: middle;
}

.logo-desc:before {
    content: "";
    display: inline-block;
    width: 0.139vw;
    height: 2.917vw;
    background-color: #E9E3E9;
    vertical-align: middle;
    margin-right: 0.903vw;
}

.login-box h1 {
    color: #201D1E;
    font-size: 1.389vw;
    line-height: 1.3;
    font-family: 'Archivo';
}

.full-height-inner-wrapper .footer p {
    font-size: 0.833vw;
    color: #201D1E;
    line-height: 1.9;
    letter-spacing: 0.007vw;
    text-align: center;
}

#Log p{
    font-size: 0.972vw;
    font-weight: 400;
    color: #686573;
    padding-bottom: 0.556vw;
}

/*=========================SIGNUP PAGES ENDS ==================*/

.form-field-group {
    min-height: 6.458vw;
    position:relative;
}

.form-field-group .ico-cal {
    position: absolute;
    top: 1.250vw;
    right: 1.042vw;
    font-size: 0.764vw;
}

.form-field-link {
    position: absolute;
    right: 0;
    font-size: 0.764vw;
    color: #444444;
    line-height: 1.2;
    top: 0.556vw;
}

.bell-icon {
    visibility: visible;
    height: 2.778vw;
    width: 2.778vw;
    border: 0.069vw solid rgba(216, 219, 232, 0.4);
    border-radius: 1.389vw;
    padding: 0.556vw;
    margin-right: 0.694vw;
}

.form-field-link:hover,
.form-field-link:focus {
    color:#46B0E6;
}

.form-field-group > label {
    font-size: 0.833vw;
    color: #201D1E;
    line-height: 1.2;
    margin-bottom: 0.278vw;}
.btn-blue{
    background: #46B0E6;
    border-radius: 0.278vw;
    border-color: #46B0E6;
}
.btn-blue:hover, .btn-blue:focus{
    border-color: #1581D6;
    color:  #fff;
}
.form-field-group .form-control {
    color: #201D1E;
    line-height: 1;
    height: auto;
    padding: 1vw 1.042vw 0.864vw;
    border-radius: 0.278vw;
    font-size: 0.972vw;
    border: 0.069vw solid rgba(187,187,187,.5);
}

.form-field-group .form-control:disabled, .form-field-group .form-control[readonly] {
    background-color:#ffffff;
}

.form-field-group .form-control:focus,
.form-control:focus{
    background-color: #FDFEFE;
    box-shadow: none;
    border-color: #46B0E6;
}
.icon-call{
    vertical-align: middle;
}
.call-icon-con{
    border-radius: 50%;
}
/*=================MEDIA QUERIES==================*/

@media (max-width: 1199px){
    .full-height-inner-wrapper {
        margin: 0 4.861vw 0 auto;
    }

    .full-height-wrapper, .full-height-box .full-height-inner-wrapper {
        max-width: 29.167vw;
        margin: 0 0 0 auto;
    }
}


@media (max-width: 575px){
    .grid-reg-text {
        padding: 0.208vw 0;
        display: inline-block;
    }

}
@media screen and (max-width: 991px) and (min-width: 993px){
    .grid-reg-text {
    }
    time{
        display: inline-block;
        width: 8.750vw;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
    .ivr-name-col, .version-name-col, .grid-date-col{
        padding-top: 0 !important;
    }
}
@media screen and (max-width: 1150px) and (min-width: 993px){
    .col.col-xl-3.grid-instance-column > img {
        vertical-align: baseline;

    }
    .ivr-name-col, .version-name-col, .grid-date-col {
        padding-top: 0.347vw !important;
        padding-bottom: 0;
    }
    .grid-status-col, .grid-status-column{
        flex: 0 0 10%;
        max-width: 10%;
    }
    .grid-action-column , .icon-mobile {
        max-width: 14%;
        flex: 0 0 14%;
    }
}





@media (max-width: 992px){
    .container {
        padding:0 2.083vw;
    }

    .logo-area img {
        width:5.208vw;
    }

    .logo-desc {
        font-size: 0.972vw;
        margin: 0.694vw 0 0 0.347vw;
    }

    .logo-desc:before {
        height: 2.083vw;
        margin-right: 0.694vw;
    }
}

@media (max-width: 340px){
    .logo-desc {
        font-size:0.903vw;
        margin: 0.694vw 0 0 0.347vw;
    }

    .logo-area img {
        width: 3.125vw;
    }
}

@media only screen
and (max-width: 1400px)
and (orientation : landscape) {
    .header-nav {
        padding:5.208vw 0 4.167vw 0;
    }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    .full-height-inner-wrapper .login-box {
        padding: 3.472vw 0 6.944vw 0;
    }

    @media (max-width: 992px) {
        .full-height-inner-wrapper .login-box {
            padding: 0;
        }
    }
}
@media(max-width: 1024px){
    .grid-status-col .responsiveTitle {
        display: none;
    }
    .grid-status-col {
        padding-left: 0;
    }
    .filtersCon .row .col-lg-3{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .mobile-sidebarham{
        display: block !important;
        position: absolute;
        top: 1.181vw;
        cursor: pointer;
        left: 1.736vw;
        z-index: 6;
    }
}

.actionBtns{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.actionIcons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
@media (max-width: 1024px){
    .ivr-name-col, .version-name-col, .grid-date-col {
        padding-top: 0;
        padding-bottom: 0;
    }

}


@media (max-width: 1200px ) and (min-width: 992px){

    .actionIcons .dropdown button {
        left: 1.181vw !important;
    }
    .grid-instance-column, .grid-instance-head-column, .grid-action-column {
        align-items: center;
        display: flex;
    }
    .col.col-xl-3.grid-instance-column > img {
        margin-left: 0.556vw;
    }


    .left-wrapper .img-wrapper {
        left: 0.625vw;
    }
    .actionBtns .custom-control {
        margin-left: 0 !important;
    }
}

@media (max-width:992px){
    .left-wrapper{
        display: none;
    }

    .centered_content {
        justify-content: center;
    }
    .container-fluid.login-container {
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        justify-content: center;
        display: flex;
    }
}
@media screen and (max-width: 992px) and (min-width: 767px){
    .col.col-xl-3.grid-instance-column > img {
        vertical-align: baseline;
    }
    time{
        padding: 0.347vw 0;
    }
    .grid-reg-text {
        padding: 0.347vw 0;
    }
    .grid-status-col,.grid-status-column{
        flex: 0 0 10%;
        max-width: 10%;
    }
    .icon-mobile,.grid-action-column{
        flex: 0 0 13% !important;
        max-width: 13%;
    }


}
@media (min-width: 1200px) {
    .actionIcons a {
        margin-right: 1.042vw;
    }
}
.slider.round{
    padding-left: 1.389vw;
}
.mobile-sidebarham{
    display: none;
}
.modal{
    z-index: 9999;
}
.avatar-dropdown-toggle.grid-drop-caret{
    cursor: pointer;
    position: relative;
    left: 0.556vw;
}
.actionIcons .dropdown button{
    position: relative;
    left: -0.486vw;
    padding: 0.347vw 0.694vw 0.347vw;
    top: 0.139vw;
}
.user_management .icon-more-vertical{
    padding: 0.347vw 0.694vw 0.347vw;

}

.user_management .icon-more-vertical:hover{
    color: rgba(51, 154, 235, 1);
    padding:0.347vw 0.694vw 0.347vw;
    background: rgba(51, 154, 235, 0.1);
    border-radius: 0.278vw;
}
.actionIcons .dropdown button:hover , .actionIcons .dropdown button:focus {
    background: rgba(51, 154, 235, 0.1);
    border-radius: 0.278vw;
    color: rgba(51, 154, 235, 1);
    padding:0.347vw 0.694vw 0.347vw;
    outline: 0;
}
.actionBtns .custom-control{
    margin-left: 1.597vw;
}

@media (max-width: 480px){

    #sidebar .sidebar-header{
        background: #fff;
    }
    #sidebar ul li a{
        color:rgba(104, 101, 115, 1) ;
    }
    #sidebar ul.components{
        padding: 1.736vw 0;
    }
    #sidebar ul li + li{
        margin-top: 0;
    }
    #sidebar ul li a{
        padding: 0.833vw 1.736vw 0.417vw;

    }
    .grid-status-col {
        padding-left: 1.250vw;
    }

    .sidebar-expand-collapse{
        padding: 1.597vw 1.736vw;
    }
    .sidebar-header .desktop-menu-ham .icon-Menu:before{
        color: #555657 !important;
        font-size: 1.111vw;
        font-weight: 400;

    }
    .collapsed .sidebar-header .desktop-menu-ham .icon-Menu:before{
        color: #555657 !important;
        font-size: 1.528vw;
        font-weight: 400;

    }
    #sidebar ul li a{
        border-radius: 0;
    }
    #sidebar .sidebar-header{
        border-bottom: 0;
    }
    .collapsed .mobile-ham-logo{
        opacity: 0;
        visibility: hidden;
    }
    .mobile-ham-logo{
        opacity: 1;
        visibility: visible;
    }
    .sidebar-header{
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .sidebar-header a{
        display: flex;
        align-items: center;
    }
    .logo-link.mobile-ham-logo{
        margin-left: 1.736vw !important;
    }

}

@media (max-width: 1024px) {


    .collapsed .sidebar-header .desktop-menu-ham .icon-Menu:before{
        color: #2D2F32;
    }
    .sidebar-header .desktop-menu-ham .icon-Menu:before{
        color: rgb(35, 35, 35);
        font-size: 1.111vw;
    }
    .collapsed .sidebar-header .desktop-menu-ham .icon-Menu:before{
        font-size: 1.528vw;
    }



}
.collapsed .mobile-ham-logo{
    display: none;
}
.mobile-ham-logo{
    display: none;
}
.collapsed .sidebar-header{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
.actionBtns .more-icon-con > .dropdown{
    top: 0;
}
.filtersCon .container-fluid .row{
    align-items: center;
}
@media only screen and ( min-width : 992px ) {
    .pageTitle {
        font-size: 1.67vw; line-height: 1.667vw; margin-bottom: 0;
    }
    .one-box{ font-size: 1.042vw; padding : 1.25vw 0.833vw 0.833vw 1.25vw;   }
    .sub-heading{
        font-size:1.38vw;
        line-height: 1.5vw;
    }
}
@media(max-width: 991px){
    .pageTitle {
        font-size:6.2vw; line-height: 7vw;
    }
    .one-box{ font-size: 1.042vw; padding : 1.25vw 0.833vw 0.833vw 1.25vw;   }
    .sub-heading{
        font-size:1.38vw;
        line-height: 1.5vw;
    }
}
.scrollbar-track-x,
.scrollbar-thumb-x {
    height: 0.347vw !important;

}

.scrollbar-track-y{
    width: 0.347vw !important;
    background: transparent;
    right: 0.556vw;
}
.scrollbar-thumb-y {
    width: 0.347vw !important;
    background: #DADADA;
}
.filterHeading{
    display: flex;
    align-items: center;
}

@media (max-width: 1199px) and (min-width: 993px){
    .row.user_table .user_details h1 {
        font-size: 0.833vw;
    }
    .row.user_table .user_details a {
        font-size: 0.694vw;
    }
    .col-lg-1 {
        flex: 0 0 5.333%;
        max-width: 5.333%;
        padding: 0 0.347vw;
    }
    .col-lg-1.contact-no {
        flex: 0 0 13.333%;
        max-width: 13.333%;
        padding: 0 0.347vw;
    }
    .col-lg-2.clients-cust{
        flex: 0 0 14.667%;
        max-width: 14.667%;
        padding: 0 0.625vw;
    }
    .col-lg-1.actions-grid {
        flex: 0 0 7.333%;
        max-width: 7.333%;
        padding: 0 0.347vw;
    }
    .col-lg-1.actions-grid .gridHeadText , .status-grid .gridHeadText{
        padding-left: 0;
    }
    .col-lg-2.last-activity-grid {
        -ms-flex: 0 0 19.666667%;
        flex: 0 0 19.667%;
        max-width: 19.667%;
        padding: 0 0.625vw;
    }
    .grid-status-col .centered_content{
        margin-left: 0 !important;
    }
    .col-lg-1.roles-type-grid{
        -ms-flex: 0 0 12.666667%;
        flex: 0 0 12.667%;
        max-width: 12.667%;
    }
    .col-lg-7.permissions-grid {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 54.333%;
        max-width: 54.333%;
    }
    .col-lg-2.ivr-version-col-head , .col-lg-2.version-name-col {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 17.667%;
        max-width: 17.667%;
        padding: 0 0.347vw !important;
    }
    .grid-reg-title {
        font-size: 0.833vw;
        padding-left: 0.278vw;
    }
    .col-lg-1.ivr-name-col-head , .col-lg-1.ivr-name-col {
        flex: 0 0 8.333%;
        max-width: 8.333%;
        padding: 0 0.347vw;
    }
    .time-with-icon .grid-reg-text time {
        padding-left: 0.417vw;
    }
    .row.user_table .centered_content {
        margin-left: 1.944vw;
    }
    .role-type{
        text-align: center;
    }
    .grid-reg-text {
        font-size: 0.833vw;
    }
    .ivr-grid-status {
        padding: 0.486vw 0.833vw 0.347vw;
    }
    .more-icons {
        left: -1.042vw;
    }
    .more-icon-con {
        left: -1.042vw;
    }
    .avatar-dropdown-toggle.grid-drop-caret {
        left: -0.208vw;
    }
    .tab-content {
        max-height: 90vh;
        overflow: scroll;
        margin-top: 1.111vw;
        scrollbar-width: none;
    }
}
.more-icon-hover button:hover,.more-icon-hover button:focus{
    background: rgba(51, 154, 235, 0.1);
    border-radius: 0.278vw;
    color: rgba(51, 154, 235, 1);
    padding: 0.347vw 0.694vw 0.347vw;
    outline: 0;

}
@media(max-width: 1023px) and (min-width: 992px){
    .col-lg-1 {
        -ms-flex: 0 0 5.333333%;
        flex: 0 0 5.333%;
        max-width: 5.333%;
    }
    .gridHeadText {
        padding-left: 0;
    }
    .ivr-grid-status {
        padding: 0.486vw 0.625vw 0.347vw;
    }
    .col-lg-2.clients-cust {
        flex: 0 0 15.667%;
        max-width: 15.667%;
        padding: 0 0.625vw;
    }
    #content {
        padding: 0;
    }
    .col-lg-1.ivr-name-col-head, .col-lg-1.ivr-name-col {
        flex: 0 0 10.333%;
        max-width: 10.333%;
        padding: 0 0.347vw;
    }
}

@media (max-width: 1150px) and (min-width: 768px){
    .more-icon-con {
        left: 0.694vw;
    }
    .actionBtns .custom-control {
        margin-left: 0;
    }
}
.avatar-dropdown-toggle.grid-drop-caret{
    transform: rotate(-90deg);

}
.grid-drop-caret.grid-drop-caret-down{

}
@media (max-width: 992px){
    body{
        overflow-x: hidden;
        overflow-y: auto;
    }
    #root .login-head {
        margin-bottom: 6.933vw;
    }
    #root .sidebar-header-bar.list-unstyled.components {
        display: none !important;
    }
    #root .option-has-icon .icon-edit-22::before {
        color: rgba(41, 45, 50, 1);
        transition: 0.2s ease;
    }
    #root .option-has-icon:hover .icon-edit-22::before{
        color: rgba(70, 176, 230, 1);
        transition: 0.2s ease;
    }
    #sidebar.collapsed {
        min-width: 0;
        max-width: 0;
        text-align: left;
        display: none;
    }
    #root .ivr-grid-con:not(.version-grid-con) > .row:not(.ivr-grid-heads) {
        cursor: pointer;
    }

    #root .filterIcon{
        display: none;
    }
    #root .ivr-modal .btn + .btn{
        margin-left: 6.132vw;
    }
    #root .avatar-dropdown .dropdown-menu {
        transition: opacity 0.5s ease-in;
        top: auto !important;
        left: 0;
        bottom: -50% !important;
        position: fixed !important;
        width: calc(100% );
        right: auto;
        border-radius: 0;
        margin-right: 0 !important;
        max-width: 100%;
        transition: opacity 0.5s ease-in;
        box-shadow: 0 0 21.333vw rgba(117, 117, 170, 0.2);
        border-top-left-radius: 5.333vw;
        border-top-right-radius: 5.333vw;
        transform: none !important;
        display: block !important;
        transition: 0.5s ease;
    }
    #root .avatar-dropdown .dropdown-menu.show {
        top: auto !important;
        left: 0;
        bottom: 0 !important;
        position: fixed !important;
        width: calc(100% );
        right: auto;
        border-radius: 0;
        margin-right: 0 !important;
        max-width: 100%;
        transition: 0.5s ease;
        box-shadow: 0 0 21.333vw rgba(117, 117, 170, 0.2);
        border-top-left-radius: 5.333vw;
        border-top-right-radius: 5.333vw;
        transform: none !important;
    }
    #root .dropdown-background-mob{
        top: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        background: #00000080;

    }
    #root .avatar-area {
        display: flex;
        padding: 16vw 4vw 5.867vw;
        padding-bottom: 5.867vw;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        padding-bottom: 6.933vw;
        background: #fff;
        border-top-left-radius: 5.333vw;
        border-top-right-radius: 5.333vw;
    }

    .react-datepicker__input-container::after{
        position: absolute;
        content: "\900d";
        font-family: "icomoon";
        right: 3.733vw;
        top: 1.333vw;
        color: rgba(136, 136, 136, 1);
    }
    #root .avatar-dropdown .dropdown-menu.show:before{
        position: absolute;
        content: "";
        height: 100vh;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        top: -100vh;
        left: 0;
    }
    #root .avatar-area a {
        font-size: 3.733vw;
    }
    #root .react-datepicker__input-container:focus input,.react-datepicker__input-container input:focus,.react-datepicker__input-container:focus-visible input,.react-datepicker__input-container input:focus-visible{
        border: 0.267vw solid #39B7E9 !important;
        background: #fff !important;
        outline: 0 !important;

    }
    #root .avatar-dropdown .dropdown-menu.show .avatar-area:before{
        content: "";
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        height: 6.667vw;
        width: 100%;
        top: 0;
        z-index: -11;


    }
    #root .btn.btn-add-version.icon-more-vertical:before{
        font-size: 5.600vw;
    }
    #root .avatar-area::after{
        content: "";
        position: absolute;
        box-shadow: 0 0 21.333vw rgba(117, 117, 170, 0.2);
        height: 100%;
        width: 100%;
        top: 50.5%;
    }
    #root .avatar-area .avatar-details {
        padding: 0;
        text-align: center;

    }
    #root .avatar-img img{
        width: 100%;
    }
    #root .avatar-img{
        height: 21.333vw;
        width: 21.333vw;
        border-radius: 50%;
        position: absolute;
        top: -10.667vw;
    }
    #root .avatar-img img {
        max-width: 100% !important;
    }
    #root .notification-dropdown .dropdown-menu.show{
        display: flex !important;
    }

    .notification-dropdown .dropdown-menu{
        top: 0 !important;
        left: 0 !important;
        transform: none !important;
        bottom: auto !important;
        position: fixed !important;
        width: 100%;
        right: auto;
        margin-right: 0 !important;
        max-width: 100%;
        transition: opacity 0.5s ease-in;
        box-shadow: 0 0 21.333vw rgba(117, 117, 170, 0.2);
        border:0 !important;
        min-width: 100vw;
        height: 100%;
        padding-top: 18.667vw !important;
        flex-direction: column;
        cursor: default;
        margin-top: 0;
    }
    #root .close-icon{
        position: absolute;
        top: 8.800vw;
        cursor: pointer;
    }
    #root .custom-control.notmodal.user-switch {
        position: relative;
        bottom: 0;
        left: 12.533vw;
        padding: 0;
        top: 2.933vw;
    }

    .row.user_table .col{
        padding-left: 0 !important;
        padding-right:0 !important;
    }
    #root .user_management .mobile-flex {
        margin-top: 5.333vw;
    }
    #root .row.user_table{
        padding-left: 6.133vw !important;
        padding-right: 4.800vw !important;
    }
    #root .clients_icon a{
        display: flex;
    }
    #root .ivr-name-col, .version-name-col, .grid-date-col {
        padding-top: 0 !important;
    }
    #root .role-btns {
        margin-right: 6.400vw;
    }
    #root .main-header header{
        display: block;
    }
    #root .delete-modal .modal-dialog {
        min-width: 95% !important;
    }
    #root .user_container.bordered-table {
        border: none;
        margin-top: 0;
    }
    .modal.ivr-modal.delete-modal:not(.publish-modal) .modal-content {
        border-radius: 5px !important;
    }
    #root .avatar-name {
        margin-right: 0;
    }
    .message-alert {
        box-shadow: 0 1.068vw 1.068vw rgba(0, 0, 0, 0.25);
        border-radius: 1.601vw;
        padding: 5.334vw;
        font-size: 4.266vw;
        line-height: 5.334vw;
    }
    .ivr-modal .modal-dialog.modal-lg , .modal-dialog.modal-lg , #clientData .modal-dialog {
        max-width: 163.200vw;
    }
    .message-alert .icon-Union1 {
        font-size: 3.199vw;
    }
    .message-alert .icon-check-circle {
        font-size: 5.334vw;
    }
    .container-role [type="checkbox"]:checked + span::after, .container-role [type="checkbox"]:not(:checked) + span::after {
        width: 1.601vw;
        height: 3.199vw;
        border-bottom: 0.534vw solid #fff;
        border-right: 0.534vw solid #fff;
        top: 0.534vw;
        left: 1.866vw;
    }
    #root .bordered-table {
        border: 0;
        margin-top: 0;
    }
    #root .avatarWrapper .dropdown {
        left: 1.067vw;
    }
    #root .grid-date-col {
        padding-bottom: 0 !important;
    }

    .filterContainer,
    .show-large {
        display: none !important;
    }
    #root .opacity-hidden{
        display: none;
    }
    #root .opacity-shown{


    }
    #root .flex-cust{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        top: 0;
        padding: 5.867vw 4.533vw;
        left: 0;
        background: white;
        box-shadow: 0 1.067vw 2.133vw rgba(0, 0, 0, 0.04);
    }
    #root .notification-header{
        margin-bottom: 0;
        padding: 4vw 4.533vw 1.067vw;
        margin-top: 2.667vw;
    }
    #root .notification-body{
        padding: 4.533vw;
    }
    #root .flex-cust .i2c-logo{
        position: relative;
        left: 2.667vw;
    }
    #root .flex-cust .close-icon{
        position: relative;
        top: 0;
        left: 1.333vw;
    }
    #root .avatar-sidebar{
        display: none;
    }
    #root .display-end  {
        display: flex;
        align-items: flex-end;
    }
    #root .show-small {
        display: block;
    }
    #root .ivr-grid-heads
        /*, #sidebar .nav-text */
    {
        display: flex;
    }

    .box-text{
        font-size: 3.467vw;
    }
    #root .box-heading{
        font-size: 3.733vw;
        margin-top: 3.467vw;
        letter-spacing: 0.080vw;
        margin-bottom: 0;
        line-height: 6.133vw;
    }
    #root .box-last-modified {
        font-size: 3.200vw;
        line-height: 4.267vw;
    }
    #root .box-last-modified-date{
        font-size: 3.200vw;
        line-height: 4.267vw;
    }
    #root .ivr-grid-con > .row > .ivr-name-col, .ivr-grid-con > .row > .version-name-col, .ivr-grid-con > .row > .grid-date-col, .ivr-grid-con > .row > .grid-status-col {
        display:flex;
        flex-direction: column;
    }
    #root .col-action > span {
        margin: 0 0 0 2.667vw;
    }
    #root .col-action .custom-control.custom-switch::after {
        left: calc(100% + 3.200vw); display: none;
    }
    #root .col-action > span.grid-drop-caret {
        /*margin-left: 5.333vw;*/
    }

    .responsiveTitle{
        font-size: 3.200vw;
        color: #8D8293;
    }
    #root .grid-reg-text{
        padding: 0.800vw 0;
    }
    #root .searchFieldCon, .ivrNameCon, .clientNameCon, .addVersionBtnCon, .addIVRBtnCon {
        max-width: 100%;
        width: 100%;margin-bottom: 2.667vw;
        height: 10.667vw;
        display: none;
    }

    .chosen-container-single .chosen-single,
    .chosen-container-single {
        width: 100% !important;
    }
    #root .searchField, #ivrName, #clientName, #addVersionBtn, #addIVRBtn {
        width: 100%;
    }
    #root .pageTitle {
        margin-bottom: 1rem;
    }
    #root .ivr-grid-con:not(.version-grid-con) > .row:not(.ivr-grid-heads) {
        position: relative;
    }
    #root .ivr-grid-con:not(.version-grid-con) > .row:not(.ivr-grid-heads) .col-action {
        position: relative;
        /* height: 100%; */
        right: 0;
        top: 0;
        width: auto;
        /* text-align: right; */
        /* display: flex; */
        /* flex-direction: column; */
        /* justify-content: flex-end; */
    }
    #root .sub-heading {
        margin: 5.600vw 0 2.667vw;
    }
    #root .select2-container {
        width: 100% !important;
    }
    #root .ivr-modal .form-row + .form-row {
        margin-top: 4vw;
    }
    #root .ivr-modal .form-row > div + div {
        margin-top: 0;
    }
    #root .one-box:nth-child(n+4) {
        margin-top: 0;
    }
    #root .actionIcons.more-icon-con .icon-more-vertical {
        transform: rotate(90deg);
    }
    /*Version page*/
    .one-box {
        flex: 0 0 100%;
        max-width: calc(100%);
    }

    .fav-icon-con span {
        font-size: 5.333vw;
    }
    #root .full-height-inner-wrapper {
        margin: 0 0 0 auto;
    }
    #root .search-data{
        position: absolute;top: 26.667vw;left: 2.933vw;z-index: 11;max-height: 189.867vw;overflow: scroll;width: 100%;
    }

    .full-height-wrapper, .full-height-box .full-height-inner-wrapper {
        max-width: 90.667vw;
    }

    .full-pg-bottom-links {
        padding: 0 8vw 8vw 8vw;
    }
    #root .alert-primary.mb-5 {
        margin-bottom:11.733vw !important;
    }
    #dropdownMenuButton {
        top: -0.800vw;
        left: -2.333vw;
    }
    #dropdownMenuButton::before {
        display: none;
    }
    #root .btn.avatar-name.new-notification {
        top: 0;
    }
    #root .responsiveTitle{
        margin-right: 1.600vw;
        font-size: 3.200vw;
        line-height: 4.267vw;
        letter-spacing: 0.107vw;
        display: inline;
        color: #3d3d3d;
        font-family: 'Archivo';

    }
    #root .grid-status-col .responsiveTitle{
        display: none;
    }
    #root .detail-label {
        margin-bottom: 1.068vw;
        font-size: 3.199vw;
        line-height: 4.266vw;
    }
    #root .user_details {
        margin-left: 2.934vw;
    }
    #root .row.user_table .user_details a {
        font-style: normal;
        font-weight: 400;
        font-size: 3.199vw;
        line-height: 3.732vw;
    }
    #root .user_image_wrapper {
        height: 10.668vw;
        width: 10.668vw;
    }
    #root .detail-desc p, .detail-desc {
        font-size: 3.199vw;
        line-height: 5.065vw;
    }
    #root .custom-switch .custom-control-label::before,
    #root .custom-switch .custom-control-label::after {
        border-radius: 2.665vw;
    }
    #root .custom-switch .custom-control-label::after {
        height: 3.732vw;
        width: 3.732vw;
        top: calc(.25rem + 0.799vw);
        left: calc(-2.25rem + 0.799vw);
    }
    #root .custom-switch .custom-control-label::before {
        height: 5.334vw;
        width: 9.066vw;

    }
    #root .roles-type-grid {
        flex: 0 0 83.33%;
        max-width: 83.33%;
    }
    #root .more-icons {
        border-radius: 3.732vw;
        min-height: 7.465vw;
        min-width: 7.465vw;
        max-width: 7.465vw;
        left: -12.347vw;
        font-size: 2.934vw;
        line-height: 3.468vw;
    }
    #root .clients_icon img:nth-child(2) {
        position: relative;
        left: -2.347vw;
    }
    #root .clients_icon img:nth-child(4) {
        position: relative;
        left: -7.042vw;
    }
    #root .clients_icon a img {
        min-height: 7.465vw;
        min-width: 7.465vw;
    }
    #root .clients_icon img:nth-child(5) {
        position: relative;
        left: -9.389vw;
    }
    #root .clients_icon img:nth-child(3) {
        position: relative;
        left: -4.694vw;
    }
    #root .grid-reg-title {
        font-weight: 500;
        font-size: 3.732vw;
        line-height: 4.266vw;
        padding-left: 3.135vw;
        padding-top: 1.067vw;
        letter-spacing: 0.2px;
}
    #root .ivr-grid-heads
        /*, #sidebar .nav-text */
    {
        display: none;
    }
    #root .icon-mobile{
        position: absolute;
        right: 4vw;
        bottom: 0;
        width: 13.333vw;
        height: 100%;
    }
    #root .icon.edit-icon{
        position: absolute;
        top: 6.400vw;
        right: 0;
    }
    #root .custom-control.notmodal {
        position: absolute;
        bottom: 6.6vw;
        left: -3.067vw;
    }
    #root .alert-primary.mt-5 {
        margin-top:11.733vw !important;
    }

    .full-pg-bg {
        background: none;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        top: initial;
        width: 100%;
        height: 100%;
        border-radius: 0;
        left: initial;
        right:initial;
    }
    #root .full-height-inner-wrapper {
        margin: 0 auto;
    }

    .full-height-wrapper, .full-height-box .full-height-inner-wrapper {
        max-width: 106.667vw;
        margin: 0 auto;
    }


    .footer p {
        margin-bottom:10.667vw;
    }
    #root .container {
        padding:0 8vw;
    }
    #root .custom-padding{
        padding-left: 0 !important;
    }
    #root .full-pg-bottom-links p:last-child {
        margin:0;
    }

    .full-height-inner-wrapper .small-wrapper {
        flex: 1 100%;
        -webkit-box-flex: 1;
        -ms-flex: 1 100%;
    }

    .full-pg-bg {
        display:none;
    }

    .full-height-wrapper, .full-height-box .full-height-inner-wrapper {
        max-width: 93.333vw;
        margin:0 auto;
    }

    .main {
        padding: 8vw 0 40vw;
    }

    .footer {
        padding: 0 0 18.667vw 0;
        margin-top: -30.667vw;
    }

    .full-height-wrapper .footer {
        padding:6.667vw 0;
        margin:0;
    }

    .form-field-group {
        min-height: 25.333vw;
    }
    #root .full-height-inner-wrapper .login-box {
        padding: 0;
    }
    #root .sidebar-expand-collapse.desktop-menu-ham{
        margin: -1.333vw 0;
    }

    .grid-status-col .responsiveTitle {
        display: none;
    }
    #root .pl-10{
        padding-left: 0 !important;
    }
    #root .version-name-col{
        padding-left: 0 !important ;
    }
    #root .with-border {
        margin: 1.600vw 0 2.133vw;
        padding: 2.667vw 4vw;
        border: 0.267vw solid #F0F0F0;
        box-sizing: border-box;
        border-radius: 1.067vw;
    }
    #root .ivr-grid-con > .row:not(.ivr-grid-heads) > .row > div.col-12, .ivr-grid-con > .row:not(.ivr-grid-heads) > .row > div.col {
        margin: 4vw 0 1.067vw;
        padding: 0;
    }
    #root .ivr-name-col, .version-name-col, .grid-date-col {
        padding-top: 6.400vw;
        padding-bottom: 0;
    }
    #root .ivr-grid-con:not(.version-grid-con) > .row:not(.ivr-grid-heads)::before {
        width: 0;
        height: 100%;
        background: #46B0E6;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        box-shadow: 0 1.067vw 2.133vw rgba(0, 0, 0, 0.07);
        border-radius: 1.067vw;
    }
    #root .grid-date-col{
        padding-bottom: 5.600vw;
    }
    #root .detail-icon{
        display: none;
    }
    #root .row-details{
        order: 8;

        background: #F2F9FF;
        border: 0.267vw solid #46B0E6;
        border-left-width: 0.800vw;
        margin-top: 0;
        padding: 0 4vw !important;
        width: 103%;
        margin-left: -2.23vw;
        margin-right: -0.5vw;
        position: relative;
        top: 1.92vw;
    }
    .col-xl-3.intello-msg .sup-lang-wrap {
        margin-left: 0;
    }
    #root .intello-msg .detail-text {
        margin-bottom: 4vw;
        margin-top: 4vw;
    }
    #root .ivr-name-col, .version-name-col, .grid-date-col {
        padding-top: 6.400vw;
        padding-bottom: 0;
    }
    #root .dropdownList.d-none{
        display: none !important;
    }
    #root .options-tooltip .icon-more-vertical{
        transform: rotate(0deg);
    }
    #root .grid-date-col{
        padding-bottom: 5.333vw;
    }
    #root .modalSwitchWrap{
        display: flex;
        align-items: center;
    }
    #root .ivr-modal .modalSwitchWrap label{
        margin-bottom: 0;
    }
    #root .logo-desc {
        display: none;
    }
    #root .upload-file-design .upload-plus-circle {
        height: 14.934vw;
        width: 14.934vw;
        font-size: 6.401vw;
    }
    #root .upload-file-design .upload-content {
        margin-left: 4.800vw;
    }
    #root .role-btns span {
        padding-left: 7.467vw;
        font-size: 3.199vw;
        line-height: 4.001vw;
        padding-top: 0.799vw;
    }
    #root .role-container {
        margin-bottom: 4.001vw !important;
    }
    #root .icons-plus {
        font-size: 8.532vw;
    }
    #root .container-role [type="radio"]:checked + span::before,
    #root .container-role [type="radio"]:not(:checked) + span::before {

        width: 5.334vw;
        height: 5.334vw;
        border: 0.265vw solid #ddd;
    }
    .container-role [type="radio"]:checked + span::after, .container-role [type="radio"]:not(:checked) + span::after {
        width: 3.732vw;
        height: 3.732vw;
        top: 0.799vw;
        left: 0.799vw;

    }
    #root .upload-content .upload-description {
        font-size: 3.199vw;
        line-height: 3.732vw;
    }
    #root .upload-content .upload-title {
        font-size: 3.732vw;
        line-height: 4.800vw;
        padding-bottom: 1.068vw;
    }
    #root .modalSwitchWrap > .custom-control.custom-switch {

        top: -0.799vw;
        right: -0.799vw;
    }

    #content {
        width: 100%;
        padding: 3.200vw 0.267vw;
        transition: all 0.3s;
        background: url("../images/bg.png") no-repeat;
        background-size: auto;
        background-size: cover;
        margin-left: 0;
    }
    #root .options-tooltip button:before{
        color: #fff;
    }
    #root .options-tooltip button{
        margin-top: 2.667vw;
    }
    #root .options-list button{
        height: 10.667vw;
        width: 10.667vw;
    }
    #root .options-tooltip{
        position: fixed;
        display: flex;
        flex-direction: column-reverse;
        bottom: 25.600vw;
        right: 1.333vw;
        z-index: 2;
        align-items:center;
    }
    #root .options-list{
        display: flex;
        flex-direction: column-reverse;
        visibility: hidden;
        opacity: 0;

    }
    #root .visibility-shown{
        visibility: visible;
        opacity: 1;
    }
    #root .rows-con::-webkit-scrollbar, .user-management::-webkit-scrollbar, .user_management::-webkit-scrollbar{
        display: none;
    }
    #root .flex-cust .hide-element{
         display: block;
     }
    #root .user_table{
        border: none;
    }
    #root .main-wrapper.login-wrapper{
        height: 100vh;
    }
    #root .avatarWrapper .dropdown-menu.notification-container.show{
        top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
    }
    #root .flex-cust #dropdownMenuButton{
        left: -0.267vw;
    }
    #root .version-bg-blur::before{
        background: url("../images/version-bg-blur.png")no-repeat;
        background-size: cover;
    }
    #root .ivr-bg-blur::before{
        background: url("../images/ivr-bg-blur.png") no-repeat;
        background-size: cover;
    }
    #root .user-bg-blur::before{
        background: url("../images/blur-bg.png") no-repeat;
        background-size: cover;
    }


    .avatarWrapper .dropdown-menu.show {
        padding: 0;
        left: 0 !important;
    }
    #root .modal-body::-webkit-scrollbar{
        display: none;
    }
    #root .modal-body .form-row{
        margin-right:0;
    }
    #root .one-box {
        font-size: 1.042vw;
        padding: 6vw 0.111vw 5vw 6.667vw;
        margin-bottom: 3.733vw;
        min-height: 54.133vw;
        box-shadow: 0 1.067vw 2.133vw rgba(0, 0, 0, 0.07);
        border-radius: 2.133vw;
    }
    #root .btn.avatar-name.new-notification::before {
        height: 3.200vw;
        width: 3.200vw;
        border: 0.667vw solid #FFF;
        top: -1.333vw;
        left: 4vw;
    }
    #root .ivr-grid-status {
        padding: 2.667vw 4vw 1.600vw;
        font-size: 2.667vw;
        line-height: 3.200vw;
        border-radius: 4.267vw;
        letter-spacing: 1px;
    }
    #root .user_management .ivr-grid-status{
        font-size: 2.133vw;
        line-height: 2.667vw;
    }
    .client-holder span {
        font-weight: 500;
        font-size: 3.468vw;
        line-height: 4.266vw;
        color: #2D2F32;
        opacity: 0.7;
        padding-left: 1.920vw;
        padding-top: 1.068vw;
    }
    .client-holder img {
        max-width: 7.465vw;
        width: 7.465vw;
    }
    #root .icon-more-vertical {
        padding: 1.333vw 2.667vw 1.333vw;
        font-size: 3.733vw;
    }
    .ivr-modal .btn.btn-secondary {
        border-radius: 1.068vw;
    }
    .modal .btn {
        border-radius: 1.068vw;
    }
    #root .call-icon-con {
        width: 7.733vw;
        height: 7.733vw;
        padding: 2.667vw;
    }
    #root .divider-mob {
        display: none;
    }
    #root .btn-add-version {
        width: 12.800vw;
        height: 12.800vw;
        box-shadow: 0 0.278vw 0.556vw rgba(0, 0, 0, 0.01);
        border-radius: 8vw;
    }
    #root .icon.side-bar-icon {
        font-size: 6.667vw;
    }
    #root .icon-Group-35481, .icon-profile-2user, .icon-user-minus span {
        font-size: 6.667vw;
    }
    #root .icon-call::before {
        font-size: 3.733vw;
    }
    .logoWrapper {
        display: none;
    }
    #root .filtersCon {
        margin-top: 3.200vw;
    }
    #root .avatar-dropdown-toggle img {
        max-width: 9.600vw;
        width: 9.6vw;
    }
    #root .avatarWrapper {
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transform: none !important;
        padding: 0 0 0 0.800vw;
    }
    #root .avatarWrapper .avatar-dropdown .dropdown-menu.show {
        top: auto !important;
    }
    #root .i2c-logo {
        display: block;
        top: -1.067vw;
        position: relative;
        max-width: 12.800vw;
        left: 0.267vw;
    }
    #root .ivr-modal label {
        font-size: 3.199vw;
        line-height: 4.001vw;
    }
    #root #addIVRLabel {
        font-size: 4.800vw;
        line-height: 5.868vw;
    }
    #root .modal .form-control {
        border-radius: 1.068vw;
        font-size: 3.468vw;
        padding-bottom: 0.265vw;
        height: 10.668vw;
        line-height: 4.266vw;
        padding-left: 4.266vw;
    }
    #root .avatar-area h1 {
        font-size: 4.266vw;
    }
    #root .filtersCon {
        margin-left: -15px;
        margin-right: -15px;
    }
    #root .textAreaField textarea {
        border-radius: 0.278vw;
        padding: 3.199vw;
        font-size: 3.468vw;
        line-height: 4.266vw;
        min-height: 21.335vw;
    }
    #root .textAreaField textarea::placeholder {
        font-size: 3.468vw;
        line-height: 4.266vw;
    }
    #root .i2c-logo img{
        width: 12.8vw;
    }
    #root .avatar-name {
        padding-right: 0;
    }
    #root .header-generic {
        background: #0000;
        box-shadow: none;
    }
    #sidebar.mobile-menu{
        display: block;
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        box-shadow: 0 0 21.333vw rgba(117, 117, 170, 0.2);
        z-index: 1;
        width: 100%;
        height: auto;
        max-width: 100%;
        min-width: 100%;
        text-align: center;
        left: auto;
        top: auto;
    }
    #sidebar.mobile-menu ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0;
    }
    #sidebar.mobile-menu ul li a{
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        padding: 0;

    }
    #sidebar.mobile-menu ul li{
        padding: 4vw 0 2.133vw;
    }
    #sidebar.mobile-menu ul li {
        font-weight: 400;
        font-size: 3.200vw;
        line-height: 4.800vw;
        color: rgba(0, 0, 0, 0.65);
        width: 20%;
        border-bottom: 0.533vw solid transparent;
    }
    #sidebar.mobile-menu ul li a span.nav-text{
        padding-top : 1.067vw;
    }
    #sidebar.mobile-menu ul li.active{
        border-bottom: 0.533vw solid #1D80D2; ;
    }

    #sidebar.mobile-menu .sidebar-header{
        display: none;
    }
    #sidebar ul li a .nav-text {
        font-weight: 400;
        font-size: 3.200vw;
        line-height: 4.800vw;
        color: rgba(0, 0, 0, 0.65);
    }
    #sidebar ul li + li {
        margin-top: 0;
    }
    #sidebar span.icon {
        margin-right: 0;
    }
    #root .btn-add-version.icon-more-vertical:before{
        color: #fff;
        font-size: 6.400vw;
    }
    #root .options-tooltip button:before{
        color: #fff;
    }
    #root .options-tooltip button{
        margin-top: 2.667vw;
    }
    #root .options-list button{
        height: 10.667vw;
        width: 10.667vw;
    }
    #root .options-tooltip{
        position: fixed;
        display: flex;
        flex-direction: column-reverse;
        bottom: 24.800vw;
        right: 4.267vw;
        z-index: 5;
        align-items:center;
    }
    #root .options-list{
        display: flex;
        flex-direction: column-reverse;
        visibility: hidden;
        opacity: 0;
        height: 0;

    }
    #root .visibility-shown{
        visibility: visible;
        opacity: 1;
        height: auto;
    }
    #root .left-wrapper{
        display: none;
    }
    #root .centered_content {
        justify-content: center;
    }
    #root .container-fluid.login-container {
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        justify-content: center;
        display: flex;
    }
    #root .copyrights-p {
        text-align: center;
        font-weight: 400;
        font-size: 3.200vw;
        line-height: 6.400vw;
        width: 100%;
        /* identical to box height, or 200% */
        text-align: center;
        bottom: 4vw;
        color: #2D2F32;


    }
    #root .ivr-modal .modal-dialog.modal-lg {
        position: absolute;
        bottom: 0;
        margin: 0 !important;
        width: 100%;
        border-top-left-radius: 6.400vw;
        border-top-right-radius: 5.333vw;
        box-shadow: 0 0 21.333vw rgba(117, 117, 170, 0.2);
    }
    #root .client-holder {
        display: flex;
        align-items: center;
        max-width: 100%;
        width: 100%;
        margin-bottom: 5.333vw;
    }
    #root .clients-list {
        height: 80vw;
        overflow: auto;
        scrollbar-width: none;
    }
    #root .clients-list .client-holder:not(:nth-child(1), :nth-child(2), :nth-child(3)) {
        margin-top: auto;
    }
    #root .modal-content {
        border-top-left-radius: 5.333vw;
        border-top-right-radius: 5.333vw;
    }
    #root .modal.fade:not(.in).bottom .modal-dialog {
        -webkit-transform: translate3d(0, 125%, 0);
        transform: translate3d(0, 125%, 0);
    }
    #root .modal-open .modal{
        top: 0;
    }

    #root .fade-mob {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: 0.3s ease-in;

    }
    #root .delete-modal h1 {
        font-size: 4.800vw;
        line-height: 6.935vw;
        margin: 6.401vw 0 4.001vw;
    }

    #root .delete-modal h3, .delete-modal p {
        font-size: 4.266vw;
        line-height: 6.401vw;
    }
    #root .modal .delete-icon {
        width: 25.601vw;
        height: 25.601vw;
    }
    #root .fade-mob:not(.show) {
        opacity: 0;
        transform: translateY(100%);
        visibility: hidden;
    }
    #root .modal-body {
        padding: 0 0 0;
        max-height: 106.667vw;
        overflow: auto;
        scrollbar-width: none;
        margin-bottom: 4.800vw;
    }
    #root .search-bar{
        border: 0.133vw solid #DBDBDB;
        box-shadow: 0 1.067vw 2.133vw rgba(0, 0, 0, 0.01);
        border-radius: 1.600vw;
        font-weight: 400;
        font-size: 3.200vw;
        line-height: 4.267vw;
        color: #000;
        padding: 6.133vw 9.333vw;
    }
    #root .search-cont span.search-icon:before {
        content: "\9011";
        font-family: 'icomoon' !important;
        color: #AAA;
        font-size: 3.467vw;
    }
    #root .search-cont span.icon-Union1:before{
        font-family: 'icomoon' !important;
        color: #000;
        font-size: 2.933vw;
    }
    #root .search-cont span.search-icon , .search-cont span.icon-Union1{
        position: absolute;
        top: 3.733vw;
        left: 3.467vw;
    }
    #root .search-cont span.icon-Union1{
        top: 3.467vw;
    }
    #root .search-cont{
        position: relative;
        max-width: 100%;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        height: 0;
    }
    #root .search-show{
        opacity: 1;
        visibility: visible;
        height: 100%;
    }
    #root .search-bar::placeholder{
        font-weight: 400;
        font-size: 3.200vw;
        line-height: 4.267vw;
        opacity: 0.7;
        color: #000;
    }
    /*.search-container-main{*/
    /*    width: 100%;*/
    /*    position: absolute;*/
    /*    height: 100vh;*/
    /*    z-index: 1111;*/
    /*    top: 5px;*/
    /*    left: 0;*/

    /*}*/
    .blur{
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
    #root .search-container-main::before{
        content: "";
    }
    #root .search-container-main {
        position: absolute;
        top: 10.667vw;
        width: 100%;
        z-index: 10;
        left: 0;
    }
    #root .toggle-filter{
        position: absolute;
        width: 100%;
        cursor: pointer;
        height: 100vh;
        top: -10.667vw;
        z-index: -11;
        margin: 0;
        min-width: 100vw;
        left: -2.400vw;
    }
    #root .search-container-main .search-cont{
        width: 95%;
        margin: 0 auto;
    }
    #root .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        font-weight: 700;
        font-size: 3.200vw;
        line-height: 4.267vw;
        color: #383945;
        text-align: left;
        padding: 0 0 3.733vw 0;
        border-bottom: 0.533vw solid #1D80D2;
        margin-left: 0;
        letter-spacing: 0.107vw;
    }
    #root .nav-heading {
        font-size: 3.200vw;
        line-height: 4.267vw;
        text-align: left;
        letter-spacing: 0.107vw;
        color: #2D2F32;
        opacity: 0.7;
        border-radius: 0 !important;
        padding: 0 0 3.867vw 0;
        margin-left: 0;
    }
    #root .nav-pills li{
        width: 33.3%;
    }
    #root .nav-pills li:nth-child(2) .nav-heading,
    #root .nav-pills li:last-child .nav-heading{
        text-align: center;
    }

    .ivr-grid-con:not(.version-grid-con) > .row.user_table.user_table:not(.ivr-grid-heads) {
        margin-top: 5.333vw;
    }
    #root .notification-body{
        max-height: 160vw;
        overflow: scroll;
    }
    #root .row.user_table {
        margin-bottom: 3.733vw;
        background: #FFFFFF;
        box-shadow: 0 1.067vw 2.133vw rgba(0, 0, 0, 0.07);
        border-radius: 3.733vw;
        padding: 5.333vw 0.533vw 5.333vw;
    }
    #root .mobile-flex{
        display: flex;
        flex-direction: column;
        margin-top: 6.933vw;
    }

    .mobile-btn{
        display: none;
    }
    #root .user-management.ivr-management .row.user_table .col.time-with-icon .mobile-flex{
        margin-top: 5.600vw;
    }
    #root .only-for-mob{
        display: block;
    }
    #root .user-management.ivr-management .row.user_table .centered_content {
        display: flex;
        justify-content: flex-start;
        margin-top: 0;
        max-width: fit-content;
        margin-bottom: 4.800vw;
    }
    #root .user_container {
        box-shadow:none;
    }
    #root .user_tabs .nav-pills li {
        padding-right: 0;
        width: 16vw;
    }
    #root .rows-con{
        max-height:calc(100vh - 70.667vw);
    }
    #root .user-management{
        max-height: calc(100vh - 58.667vw);

    }
    #root .search-body .user-management {
        max-height: calc(100vh - 26.667vw);
    }
    #root .user_management{
        max-height: calc(100vh - 57.333vw);

    }
    #root .rows-con, .user-management, .user_management {
        overflow: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .modal-content {
        padding: 9.867vw 6.133vw 8vw;
        border: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    #addIVRLabel small {
        font-size: 3.733vw;
        line-height: 5.867vw;
        color: #585858;
        margin-top: 2.665vw;
    }
    #root .modal-header {
        margin: 0 0 6.935vw;
    }
    #root .upload-file-design {
        display: flex;
        align-items: normal;
        margin-bottom: 5.067vw;
        flex-direction: column;
    }
    #root .show-message{
        opacity: 1;
        visibility: visible;

    }

    .ivr-modal .upload-file-design label {
        justify-content: flex-start;
        padding: 2.667vw 4.800vw;
    }
    #root .ivr-modal .forms-field {
        margin-bottom: 4.267vw;
    }
    #root .ivr-modal .btn {
        width: 100%;
        font-size: 3.199vw;
        line-height: 4.266vw;
        height: 10.668vw;
        padding: 1.068vw 0 0;
    }
    #root .modal-footer {
        top: -5.867vw;
        left: -0.267vw;
        margin-top: 7.999vw;
    }
    #root .modal-body.custom-position{
        top: -2.400vw
    }
    #root .modal-body.custom-position .forms-field{
        margin-bottom: 4.533vw;
    }
    #addIVR small {
        max-width: 85%;
    }
    #root .row-details {
        padding: 0 !important;
    }
    #root .col.col-xl-3.grid-instance-column > img {
        margin-left: 0;
    }
    #root .actionIcons .dropdown button {
        left: 4.667vw;
        top: 3.200vw;
    }
    .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(3.625vw);
    }
    #root .react-datepicker__input-container input {
        border-radius: 1.068vw;
        font-size: 3.468vw;
        padding-bottom: 0.265vw;
        height: 10.668vw;
        line-height: 4.266vw;
        padding-left: 4.266vw;
    }
    #root .user-management.ivr-management .row.user_table {
        padding-left: 2.133vw !important;
        padding-right: 0.800vw !important;
    }
    #root .user-management.ivr-management .row.user_table {
        padding-top: 5.600vw;
        padding-bottom: 4.533vw;

    }
    #root .user-management.ivr-management .grid-reg-text {
        padding-left: 0;
        letter-spacing: 0.053vw;
    }
    #root .user-management.ivr-management .row.user_table .col {
        padding-left: 4vw !important;
        padding-right: 4vw !important;
    }
    #root .header-generic {
        padding: 2.667vw 2.667vw;
    }
    #root .user_container .nav.nav-pills {
        margin-top: -0.267vw;
        margin-bottom: 6.133vw;
        border-bottom: 0.133vw solid #C2C2C2;
    }
    #root .user_data {
        padding-left: 0;
        margin-bottom: 1.600vw;
    }
    #root .user_management .more-icon-con {
        left: 4.533vw;
        top: -5.867vw;
    }
    #root .row.user_table .centered_content {
        max-width: 34.667vw;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
    }
    #root .row.user_table .centered_content .ivr-grid-status{
        margin-top: 0;
        padding: 2vw 4.3vw 1.067vw;
        position: relative;
        top: 0.800vw;
        margin-left: 0;
    }
    #root .row.user_table.roles_table {
        padding-top: 5.333vw;
        padding-bottom: 4.800vw;
    }
    #root .clients_icon {
        margin-top: 0;
        position: relative;
        top: 2.133vw;
    }
    #root .user_management .row.user_table.roles_table .user-actions.more-icon-con {
        left: -2.133vw;
        top: 5.333vw;
    }
    #root .user_management .row.user_table.roles_table .mobile-flex {
        margin-top: 6.667vw;
    }
    #root .clients-cust .responsiveTitle{
        position: relative;
        top: 0.800vw;
    }
    #root .grid-reg-text time{
        padding-left: 2.667vw;
        position: relative;
        top: 0.267vw;
    }
    #root .grid-reg-text {
        font-weight: 500;
        font-size: 3.200vw;
        line-height: 4.267vw;
        color: rgba(45, 47, 50, 0.7);
        font-family: 'Archivo';
    }
    #root .user_body {
        box-shadow: none;
    }
    #root .pageTitle {
        font-size: 5.333vw;
        line-height: 6.400vw;
        margin-bottom: 5.867vw;
        margin-top: 2.665vw;
    }
    #root .box-text {
        font-size: 3.200vw;
        margin-bottom: 4.533vw;
        margin-top: 1.067vw;
        letter-spacing: 0.107vw;
        padding-right: 6.667vw;
        line-height: 5.067vw;
    }
    #root .version-grid-con .more-icon-con {
        left: -2.667vw;
        top: -3.200vw;
    }
    #root .call-icon {
        left: -6.533vw;
    }
    #root .version-pills .nav-heading{
        border-bottom: 0.133vw solid #C2C2C2;
    }
    #root .list-tabs li{
        font-style: normal;
        font-weight: 700;
        font-size: 5.333vw;
        line-height: 6.400vw;
        opacity: 0.5;
        color: #2D2F32;
    }
    .grid-instance-column{
        display: flex;
        align-items: center;
    }
    #root .list-tabs{
        display: flex;
        cursor: pointer;

    }
    #root .list-tabs li.active{
        opacity: 1;

    }
    #root .list-tabs li:first-child{
        margin-right: 10.667vw;
    }
    #root .filter-modal li{
        list-style: none;


    }
    #root .version-list li.active{
        background: rgba(246, 246, 246, 1);
        position: relative;
        border-radius: 1.067vw;
    }
    #root .version-list li.active:after{
        content: "\9001";
        color: #4caa75;
        font-family: icomoon;
        position: absolute;
        top: 0;
        right: 5.333vw;
        font-size: 3.200vw;

    }

    .filter-modal ul{
        padding: 0;
        margin-bottom: 0;
    }
    #root .version-list{
        margin-top: 4.267vw;
    }
    #root .version-list li{
        font-weight: 400;
        font-size: 4.267vw;
        line-height: 14.933vw;
        color: #4B4B4B;
        cursor: pointer;
        padding: 0 2.667vw;
    }
    #root .col.col-xl-3.grid-instance-column > img {
        min-width: 8.533vw;
        width: 8.533vw;
    }
    #root .version-list li:hover{

        background: #F6F6F6;
        border-radius: 1.067vw;
        padding: 0 2.667vw;

    }
    #root .user_management .container-fluid:nth-child(2n+3) .row.user_table, .user-management .container-fluid:nth-child(2n+3) .row.user_table {
        background: #fff;
    }
    .user-management {
        overflow-y: auto;
        padding-bottom: 0;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-x: hidden;
    }
    #root .text-with-bg {
        width: 100%;
        padding: 4.001vw 6.401vw;
    }
    #root .schedule-icon {

        height: 25.601vw;
        width: 25.601vw;
        font-size: 9.066vw;

    }
    #root .text-with-bg h4 {
        font-size: 4.267vw;
        line-height: 5.067vw;
        display: flex;
    }
    #root .text-with-bg h4 span {
        padding-top: 0vw;
    }
    #root .schedule-changes h1 {
        font-size: 4.267vw;
    }

    .user_management .row.user_table .ivr-grid-status {
        font-size: 2.133vw;
    }
    #root .user_management .roles_table .custom-margin {
        margin-top: 2vw;
    }
    #root .permissions_box {
        padding: 2.667vw 2.667vw 1.867vw;
        margin-right: 1.333vw;
        margin-bottom: 1.333vw;
        font-size: 2.665vw;
        line-height: 3.199vw;
    }
    #root .container-role [type="checkbox"]:checked + span::before,
    #root .container-role [type="checkbox"]:not(:checked) + span::before {

        width: 5.334vw;
        height: 5.334vw;
        border: 0.265vw solid #ddd;
    }
    #root .ivr-modal .mand-field {
        font-size: 3.199vw;
        line-height: 4.001vw;
    }
    #root .more-roles {
        font-size: 3.199vw;
        line-height: 4.266vw;
    }
    #root .user_management .user-actions.more-icon-con {
        left: 0.267vw;
        top: 2.133vw;
    }
    #root .dropdown-item.option-has-icon > span.icon-pencil-icon{
        font-size: 4.533vw;
    }
    #root .ivr-modal .roles-list-modal label {
        width: 50%;
        margin-bottom: 4.267vw;
        margin-right: 0;
    }
    #root .modal .plus-icon {
        right: 5.334vw;
    }
    #root .dropdown-item.option-has-icon .icon-upload-icon {
        font-size: 4.266vw;
    }
    #root .avatarWrapper .avatar-dropdown .dropdown-items {
        padding: 0;
        position: relative;
        z-index: 10;
    }
    #root .avatarWrapper .avatar-dropdown .dropdown-items .dropdown-item{
        padding: 8.533vw 7.467vw 8.267vw;
        display: flex;align-items: center;
        font-weight: 400;
        font-size: 3.733vw;
        line-height: 5.333vw;
        color: #2D2F32;


    }
    #root .avatarWrapper .avatar-dropdown .dropdown-items .dropdown-item span.icon{
        font-size: 5.333vw;
        padding-right: 5.333vw;
    }
    #root .avatarWrapper .avatar-dropdown .dropdown-items .dropdown-item span:not(.icon){
        padding: 1.067vw 0 0;
    }
    #root .avatarWrapper .avatar-dropdown .dropdown-items .dropdown-item:not(:last-child){
        border-bottom:0.267vw solid #E7E7E7;
        border-radius: 0;

    }
    #root .avatarWrapper .avatar-dropdown .dropdown-divider{
        display: none;
    }
    #root .actionBtns, .actionIcons {
        align-items: flex-start;
        height: 100%;
    }
    #root .message-alert {
        right: 0;
        bottom: 3.200vw;
        z-index: 5;
        width: 94%;
        left: 0;
        margin: 0 auto;
    }
    #root .message-alert .message-text {
        padding: 1.067vw 3.467vw 0 1.867vw;
        font-size: 3.733vw;
    }

    #root .notification-header h1 {
        font-weight: 700;
        line-height: 5.333vw;
        font-size: 5.333vw;
    }
    #root .notification-header span {
        font-size: 3.1987vw;
        line-height: 4.262vw;
        border-radius: 5.065vw;
        padding: 1.332vw 3.199vw 0.534vw;
    }
    #root .grid-reg-text img {
        width: 4.266vw;
    }
    #root .notification-body .new .notification-content h2 {
        font-weight: 700;
        font-size: 3.732vw;
        line-height: 4.266vw;
    }
    #root .notification-body .notification-content span {
        font-size: 3.199vw;
        line-height: 4.266vw;
    }
    #root .notification-content {
        margin-left: 3.199vw;
    }
    .notification-body .new p {
        color: #000;
        font-size: 3.199vw;
    }
    .notification-body p {
        font-size: 3.199vw;
    }
    .notification-body .img-wrapper span {
        padding: 1.601vw;
        width: 8.532vw;
        height: 8.532vw;
    }
    .notification-body .img-wrapper.new::before {
        top: -0.265vw;
        left: 1.068vw;
        height: 1.601vw;
        width: 1.601vw;

    }.dropdown-item.option-has-icon p {
         font-size: 3.199vw;
         line-height: 4.001vw;
         padding: 0.534vw 0 0;
     }
    .dropdown-menu {
        border-radius: 1.068vw;
        padding: 4.001vw 1.332vw 4.001vw;
    }
    .more-icon-con .dropdown-item {
        height: 9.066vw;
        border-radius: 1.068vw;
        padding: 0 3.732vw;
    }

    .dropdown-item.option-has-icon > span {
        margin-right: 2.665vw;
        font-size: 5.065vw;
    }
    .dropdown-item {
        font-size: 3.468vw;
        line-height: 2.665vw;
    }
    #root .notification-box {
        padding: 6.400vw 0;
    }
    .notification-body .notification-content h2 {
        font-size: 3.732vw;
        line-height: 4.266vw;
    }
    #root .grid-reg-text {
        padding-left: 0;
    }
    .header-notification span {
        font-size: 6.933vw;
    }
    .login-box h1 {
        font-size: 5.334vw;
        line-height: 1.3;
    }
    #Log p {
        font-size: 3.732vw;
        padding-bottom: 2.135vw;
    }
    .form-field-group > label {
        font-size: 3.199vw;
        margin-bottom: 1.068vw;
    }
    .form-field-group .form-control {
        padding: 4.266vw 4.001vw 2.934vw;
        border-radius: 1.068vw;
        font-size: 3.732vw;
        border:0.265vw solid rgba(187,187,187,.5);
    }
    .login-head .logo-area img {
        width: 19.999vw;
    }
    .slider.round {
        padding-left: 5.334vw;
        border-radius: 2.665vw;
    }
    .switch-field {
        width: 7.734vw;
        height: 4.266vw;
        font-size: 3.199vw;
        margin-right: 2.665vw;
    }
    .slider::before {
        height: 4.266vw;
        width: 4.266vw;
        left: -0.265vw;
        bottom: -0.265vw;
    }
    input:checked + .slider::before {
        transform: translateX(3.468vw);
    }

    .btn-blue {
        border-radius: 1.068vw;
        font-size: 3.199vw;
        padding: 2.934vw 8.268vw 2.135vw;
    }
}




@media (min-width: 1600px){
    .avatar-dropdown-toggle.grid-drop-caret {
        left: 0.556vw;
    }

}
.avatar-dropdown-toggle.grid-drop-caret span{
    font-size: 0.694vw;
    color: #767676;
}
@media(max-width: 1600px){

    .one-box {
        font-size: 1.042vw;
        padding: 1.667vw 1.111vw 0.9vw 1.667vw;
    }
    .box-text {
        font-size: 0.972vw;
        line-height: 1.528vw;
        color: #767676;
        font-family: 'Archivo';
        margin-bottom: 2.586vw;
        padding-right: 0.972vw;
        font-weight: 400;
    }


    .more-icon-con > .dropdown {

        display: inline-block;
        cursor: pointer;

    }


}
.i2c-logo , .mobile-menu{
    display: none;
}


@media(max-width: 1199px){
    .call-icon {
        left: 0;
        top: 0;
        justify-content: flex-end;
        display: flex;
    }
    .one-box {
        font-size: 1.042vw;
        padding: 1.667vw 1.111vw 1vw 1.667vw;
    }
    .rows-con .one-box:nth-child(5n+5){
        margin-right: 0;
    }
    .one-box {
        margin-bottom: 1.667vw;
    }
}

.flex-cust .hide-element{
    display: none;
}

.upload-btns-radio {
  display: flex;
    justify-content: center;
}
.upload-btns-radio .role-btns {
    margin-right: 1.736vw;
}
.more-roles{
    font-weight: 700;
    font-size: 0.833vw;
    line-height: 1.111vw;
    text-align: center;

    color: #46B0E6;
}
@media(max-width: 420px){
    .logoWrapper {
        display: none;
    }

}

@media (max-width: 1366px ) and (min-width: 1200px){
    .more-icon-con {
        left: -0.694vw;
    }
    .actionBtns .custom-control {
        margin-left: 0;
    }
    .avatar-dropdown-toggle.grid-drop-caret {
        left: -0.139vw;
    }
    .more-icon-con > .dropdown {
        left: 0.625vw;
    }
}


@media (max-width: 991px) and (min-width: 641px){




    #root .ivr-modal .btn + .btn{
        margin-left: 3.587vw;
    }
    #root .avatar-dropdown .dropdown-menu {

        box-shadow: 0 0 12.480vw rgba(117, 117, 170, 0.2);
        border-top-left-radius: 3.120vw;
        border-top-right-radius: 3.120vw;
    }
    #root .avatar-dropdown .dropdown-menu.show {

        box-shadow: 0 0 12.480vw rgba(117, 117, 170, 0.2);
        border-top-left-radius: 3.120vw;
        border-top-right-radius: 3.120vw;
    }

    #root .avatar-area {
        padding: 9.360vw 2.340vw 3.432vw;
        padding-bottom: 3.432vw;
        padding-bottom: 2.373vw;
        border-top-left-radius: 3.120vw;
        border-top-right-radius: 3.120vw;
    }

    .react-datepicker__input-container::after{
        right: 2.184vw;
        top: 0.780vw;
    }

    #root .avatar-area a {
        font-size: 2.184vw;
    }
    #root .react-datepicker__input-container:focus input,.react-datepicker__input-container input:focus,.react-datepicker__input-container:focus-visible input,.react-datepicker__input-container input:focus-visible{
        border: 0.156vw solid #39B7E9 !important;

    }
    #root .avatar-dropdown .dropdown-menu.show .avatar-area:before{
        height: 3.900vw;
    }
    #root .btn.btn-add-version.icon-more-vertical:before{
        font-size: 3.276vw;
    }
    #root .avatar-area::after{
        box-shadow: 0 0 12.480vw rgba(117, 117, 170, 0.2);
    }

    #root .avatar-img{
        height: 12.480vw;
        width: 12.480vw;
        top: -6.240vw;
    }
    .notification-dropdown .dropdown-menu{
        box-shadow: 0 0 12.480vw rgba(117, 117, 170, 0.2);
        min-width: 58.502vw;
        padding-top: 10.921vw !important;
    }
    #root .close-icon{
        top: 5.148vw;
    }
    #root .custom-control.notmodal.user-switch {
        left: 7.332vw;
        top: 1.716vw;
    }

    #root .user_management .mobile-flex {
        margin-top: 3.120vw;
    }
    #root .row.user_table{
        padding-left: 3.588vw !important;
        padding-right: 2.808vw !important;
    }

    #root .role-btns {
        margin-right: 3.744vw;
    }

    #root .flex-cust{
        padding: 3.432vw 2.652vw;
        box-shadow: 0 0.624vw 1.248vw rgba(0, 0, 0, 0.04);
    }
    #root .notification-header{
        padding: 2.340vw 2.652vw 0.624vw;
        margin-top: 1.560vw;
    }
    #root .notification-body{
        padding: 2.652vw;
    }
    #root .flex-cust .i2c-logo{
        left: 1.560vw;
    }
    #root .flex-cust .close-icon{
        left: 0.780vw;
    }

    .box-text{
        font-size: 2.028vw;
    }
    #root .box-heading{
        font-size: 2.184vw;
        margin-top: 2.028vw;
        letter-spacing: 0.047vw;
        line-height: 3.588vw;
    }
    #root .box-last-modified {
        font-size: 1.872vw;
        line-height: 3.744vw;
    }
    #root .box-last-modified-date{
        font-size: 2.184vw;
        line-height: 3.744vw;
    }
    #root .col-action > span {
        margin: 0 0 0 1.560vw;
    }
    #root .col-action .custom-control.custom-switch::after {
        left: calc(100% + 1.872vw); display: none;
    }

    .responsiveTitle{
        font-size: 1.872vw;
    }
    #root .grid-reg-text{
        padding: 0.468vw 0;
    }
    #root .searchFieldCon, .ivrNameCon, .clientNameCon, .addVersionBtnCon, .addIVRBtnCon {
        margin-bottom: 1.560vw;
        height: 6.240vw;
    }

    #root .sub-heading {
        margin: 3.276vw 0 1.560vw;
    }
    #root .ivr-modal .form-row + .form-row {
        margin-top: 2.340vw;
    }

    .fav-icon-con span {
        font-size: 3.120vw;
    }
    #root .search-data{
        top: 15.601vw;left: 1.716vw;max-height: 111.077vw;
    }

    .full-height-wrapper, .full-height-box .full-height-inner-wrapper {
        max-width: 53.042vw;
    }

    .full-pg-bottom-links {
        padding: 0 4.680vw 4.680vw 4.680vw;
    }
    #root .alert-primary.mb-5 {
        margin-bottom:6.864vw !important;
    }
    #dropdownMenuButton {
        top: -0.468vw;
        left: -0.780vw;
    }

    #root .responsiveTitle{
        margin-right: 0.936vw;
        font-size: 1.872vw;
        line-height: 2.496vw;
        letter-spacing: 0.063vw;
    }
    #root .detail-label {
        margin-bottom: 0.625vw;
        font-size: 1.871vw;
        line-height: 2.496vw;
    }
    #root .user_details {
        margin-left: 1.716vw;
    }
    #root .row.user_table .user_details a {
        font-size: 1.871vw;
        line-height: 2.183vw;
    }
    #root .user_image_wrapper {
        height: 6.241vw;
        width: 6.241vw;
    }
    #root .detail-desc p, .detail-desc {
        font-size: 1.871vw;
        line-height: 2.963vw;
    }
    #root .custom-switch .custom-control-label::before,
    #root .custom-switch .custom-control-label::after {
        border-radius: 1.559vw;
    }
    #root .custom-switch .custom-control-label::after {
        height: 2.183vw;
        width: 2.183vw;
        top: calc(.25rem + 0.467vw);
        left: calc(-2.25rem + 0.467vw);
    }
    #root .custom-switch .custom-control-label::before {
        height: 3.121vw;
        width: 5.304vw;

    }
    #root .grid-reg-title {
        font-size: 2.183vw;
        line-height: 2.496vw;
        padding-left: 1.249vw;
    }

    #root .icon-mobile{
        right: 2.340vw;
        width: 7.800vw;
    }
    #root .icon.edit-icon{
        top: 3.744vw;
    }
    #root .custom-control.notmodal {
        bottom: 3.276vw;
        left: -2.964vw;
    }
    #root .alert-primary.mt-5 {
        margin-top:6.864vw !important;
    }

    .full-height-wrapper, .full-height-box .full-height-inner-wrapper {
        max-width: 62.403vw;
    }


    .footer p {
        margin-bottom:6.240vw;
    }
    #root .container {
        padding:0 4.680vw;
    }

    .full-height-wrapper, .full-height-box .full-height-inner-wrapper {
        max-width: 54.602vw;
        margin:0 auto;
    }

    .main {
        padding: 4.680vw 0 23.401vw;
    }

    .footer {
        padding: 0 0 10.921vw 0;
        margin-top: -17.941vw;
    }

    .full-height-wrapper .footer {
        padding:3.900vw 0;
        margin:0;
    }

    .form-field-group {
        min-height: 17.820vw;
    }
    #root .sidebar-expand-collapse.desktop-menu-ham{
        margin: -0.780vw 0;
    }
    .slider::before {
        height: 3.266vw;
        width: 3.266vw;
    }
    .login-box .btn-blue {
        border-radius: 1.068vw;
        font-size: 2.199vw;
        padding: 2.434vw 6.268vw 1.635vw;
    }
    .switch-field {
        width: 6.734vw;
        height: 3.266vw;
    }
    #root .with-border {
        margin: 0.936vw 0 1.248vw;
        padding: 1.560vw 2.340vw;
        border: 0.156vw solid #F0F0F0;
        border-radius: 0.624vw;
    }
    #root .ivr-grid-con > .row:not(.ivr-grid-heads) > .row > div.col-12, .ivr-grid-con > .row:not(.ivr-grid-heads) > .row > div.col {
        margin: 2.340vw 0 0.624vw;
    }
    #root .ivr-name-col, .version-name-col, .grid-date-col {
        padding-top: 3.744vw;
    }
    #root .ivr-grid-con:not(.version-grid-con) > .row:not(.ivr-grid-heads)::before {
        box-shadow: 0 0.624vw 1.248vw rgba(0, 0, 0, 0.07);
        border-radius: 0.624vw;
    }
    #root .grid-date-col{
        padding-bottom: 3.276vw;
    }

    #root .row-details{
        border: 0.156vw solid #46B0E6;
        border-left-width: 0.468vw;
        padding: 0 2.340vw !important;
        margin-left: -1.23vw;
    }
    #root .intello-msg .detail-text {
        margin-bottom: 2.340vw;
        margin-top: 2.340vw;
    }
    #root .ivr-name-col, .version-name-col, .grid-date-col {
        padding-top: 3.744vw;
    }

    #root .grid-date-col{
        padding-bottom: 3.120vw;
    }
    .form-field-group > label {
        font-size: 2.199vw;
        margin-bottom: 1.068vw;
    }
    #root .upload-file-design .upload-plus-circle {
        height: 8.737vw;
        width: 8.737vw;
        font-size: 3.745vw;
    }
    .form-field-group .form-control {
        padding: 3.266vw 3.001vw 1.934vw;
        border-radius: 1.068vw;
        font-size: 2.732vw;
        border: 0.265vw solid rgba(187,187,187,.5);
    }
    .switch-field {
        font-size: 2.199vw;
    }
    #root .upload-file-design .upload-content {
        margin-left: 2.808vw;
    }
    #root .role-btns span {
        padding-left: 4.368vw;
        font-size: 1.871vw;
        line-height: 2.341vw;
        padding-top: 0.467vw;
    }
    #root .role-container {
        margin-bottom: 2.341vw !important;
    }
    #root .icons-plus {
        font-size: 4.991vw;
    }
    #root .container-role [type="radio"]:checked + span::before,
    #root .container-role [type="radio"]:not(:checked) + span::before {

        width: 3.121vw;
        height: 3.121vw;
        border: 0.155vw solid #ddd;
    }
    .container-role [type="radio"]:checked + span::after, .container-role [type="radio"]:not(:checked) + span::after {
        width: 2.183vw;
        height: 2.183vw;
        top: 0.467vw;
        left: 0.467vw;

    }
    #root .upload-content .upload-description {
        font-size: 1.871vw;
        line-height: 2.183vw;
    }
    #root .upload-content .upload-title {
        font-size: 2.183vw;
        line-height: 2.808vw;
        padding-bottom: 0.625vw;
    }
    #root .modalSwitchWrap > .custom-control.custom-switch {

        top: -0.467vw;
        right: -0.467vw;
    }

    #content {
        padding: 1.872vw 0.156vw;
    }

    #root .options-tooltip button{
        margin-top: 1.560vw;
    }
    #root .options-list button{
        height: 6.240vw;
        width: 6.240vw;
    }
    #root .options-tooltip{
        bottom: 14.977vw;
        right: 0.780vw;
    }
    #root .flex-cust #dropdownMenuButton{
        left: -0.156vw;
    }

    #root .one-box {
        font-size: 0.610vw;
        padding: 3.510vw 0.065vw 2.925vw 3.900vw;
        margin-bottom: 2.184vw;
        min-height: 31.669vw;
        box-shadow: 0 0.624vw 1.248vw rgba(0, 0, 0, 0.07);
        border-radius: 1.248vw;
    }
    #root .nav-pills li:nth-child(2) .nav-heading, #root .nav-pills li:last-child .nav-heading {
        text-align: center;
    }
    #root .btn.avatar-name.new-notification::before {
        height: 1.872vw;
        width: 1.872vw;
        border: 0.390vw solid #FFF;
        top: -0.780vw;
        left: 2.140vw;
    }
    #root .ivr-grid-status {
        padding:1.560vw 3.120vw 0.936vw;
        font-size: 1.560vw;
        line-height: 1.872vw;
        border-radius: 2.496vw;
    }
    #root .icon-more-vertical {
        padding: 0.780vw 1.560vw 0.780vw;
        font-size: 2.184vw;
    }
    #root .call-icon-con {
        width: 4.524vw;
        height: 4.524vw;
        padding: 1.560vw;
    }
    #root .btn-add-version {
        width: 7.488vw;
        height: 7.488vw;
        box-shadow: 0 0.163vw 0.325vw rgba(0, 0, 0, 0.01);
        border-radius: 4.680vw;
    }
    #root .icon.side-bar-icon {
        font-size: 3.900vw;
    }
    #root .icon-Group-35481, .icon-profile-2user, .icon-user-minus span {
        font-size: 3.900vw;
    }
    #root .icon-call::before {
        font-size: 2.184vw;
    }
    #root .filtersCon {
        margin-top: 1.872vw;
    }
    #root .avatar-dropdown-toggle img {
        max-width: 5.616vw;
    }
    #root .avatarWrapper {
        padding: 0 0 0 0.468vw;
    }
    #root .i2c-logo {
        top: -0.624vw;
        max-width: 7.488vw;
        left: 0.156vw;
    }
    #root .ivr-modal label {
        font-size: 1.871vw;
        line-height: 2.341vw;
    }
    #root #addIVRLabel {
        font-size: 2.808vw;
        line-height: 3.433vw;
    }
    #root .modal .form-control {
        border-radius: 0.625vw;
        font-size: 2.029vw;
        padding-bottom: 0.155vw;
        height: 6.241vw;
        line-height: 2.496vw;
        padding-left: 2.496vw;
    }
    #root .avatar-area h1 {
        font-size: 2.496vw;
    }
    #root .textAreaField textarea {
        border-radius: 0.163vw;
        padding: 1.871vw;
        font-size: 2.029vw;
        line-height: 2.496vw;
        min-height: 12.481vw;
    }
    #root .textAreaField textarea::placeholder {
        font-size: 2.029vw;
        line-height: 2.496vw;
    }
    #root .i2c-logo img{
        width: 7.488vw;
    }
    #sidebar.mobile-menu{
        box-shadow: 0 0 12.480vw rgba(117, 117, 170, 0.2);
    }
    #sidebar.mobile-menu ul li{
        padding: 2.340vw 0 1.248vw;
    }
    #sidebar.mobile-menu ul li {
        font-size: 1.872vw;
        line-height: 2.808vw;
        border-bottom: 0.312vw solid transparent;
    }
    #sidebar.mobile-menu ul li a span.nav-text{
        padding-top : 0.624vw;
    }
    #sidebar.mobile-menu ul li.active{
        border-bottom: 0.312vw solid #1D80D2; ;
    }

    #sidebar ul li a .nav-text {
        font-size: 1.872vw;
        line-height: 2.808vw;
    }

    #root .btn-add-version.icon-more-vertical:before{
        font-size: 3.744vw;
    }

    #root .options-tooltip button{
        margin-top: 1.560vw;
    }
    #root .options-list button{
        height: 6.240vw;
        width: 6.240vw;
    }
    #root .options-tooltip{
        bottom: 14.509vw;
        right: 2.496vw;
    }
    #root .copyrights-p {
        font-size: 1.872vw;
        line-height: 3.744vw;
        bottom: 2.340vw;
    }
    #root .ivr-modal .modal-dialog.modal-lg {
        border-top-left-radius: 3.744vw;
        border-top-right-radius: 3.120vw;
        box-shadow: 0 0 12.480vw rgba(117, 117, 170, 0.2);
    }
    #root .client-holder {
        margin-bottom: 3.120vw;
    }
    #root .clients-list {
        height: 46.802vw;
    }
    #root .modal-content {
        border-top-left-radius: 3.120vw;
        border-top-right-radius: 3.120vw;
    }
    #root .delete-modal h1 {
        font-size: 2.808vw;
        line-height: 4.057vw;
        margin: 3.745vw 0 2.341vw;
    }

    #root .delete-modal h3, .delete-modal p {
        font-size: 2.496vw;
        line-height: 3.745vw;
    }
    #root .modal .delete-icon {
        width: 14.977vw;
        height: 14.977vw;
    }
    #root .modal-body {
        max-height: 62.403vw;
        margin-bottom: 2.808vw;
    }
    #root .search-bar{
        border: 0.078vw solid #DBDBDB;
        box-shadow: 0 0.624vw 1.248vw rgba(0, 0, 0, 0.01);
        border-radius: 0.936vw;
        font-size: 1.872vw;
        line-height: 2.496vw;
        padding: 3.588vw 5.460vw;
    }
    #root .search-cont span.search-icon:before {
        font-size: 2.028vw;
    }
    #root .search-cont span.icon-Union1:before{
        font-size: 1.716vw;
    }
    #root .search-cont span.search-icon , .search-cont span.icon-Union1{
        top: 2.184vw;
        left: 2.028vw;
    }
    #root .search-cont span.icon-Union1{
        top: 2.028vw;
    }
    #root .search-bar::placeholder{
        font-size: 1.872vw;
        line-height: 2.496vw;
    }
    #root .search-container-main {
        top: 6.240vw;
    }
    #root .toggle-filter{
        height: 100vh;
        top: -6.240vw;
        min-width: 58.502vw;
        left: -1.404vw;
    }

    #root .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        font-size: 3.2vw;
        line-height: 2.496vw;
        padding: 0 0 2.184vw 0;
        border-bottom: 0.312vw solid #1D80D2;
        letter-spacing: 0.063vw;
    }
    #root .nav-heading {
        font-size: 3.2vw;
        line-height: 2.496vw;
        letter-spacing: 0.063vw;
        padding: 0 0 2.262vw 0;
    }
    .ivr-grid-con:not(.version-grid-con) > .row.user_table.user_table:not(.ivr-grid-heads) {
        margin-top: 3.120vw;
    }
    #root .notification-body{
        max-height: 93.604vw;
    }
    #root .row.user_table {
        margin-bottom: 2.184vw;
        box-shadow: 0 0.624vw 1.248vw rgba(0, 0, 0, 0.07);
        border-radius: 2.184vw;
        padding: 3.120vw 0.312vw 3.120vw;
    }
    #root .mobile-flex{
        margin-top: 2.373vw;
    }
    #root .user-management.ivr-management .row.user_table .col.time-with-icon .mobile-flex{
        margin-top: 3.276vw;
    }
    #root .user-management.ivr-management .row.user_table .centered_content {
        margin-bottom: 2.808vw;
    }
    #root .user_tabs .nav-pills li {
        width: 9.360vw;
    }
    #root .rows-con{
        max-height:calc(100vh - 41.342vw);
    }
    #root .user-management{
        max-height: calc(100vh - 34.322vw);

    }
    #root .search-body .user-management {
        max-height: calc(100vh - 15.601vw);
    }
    #root .user_management{
        max-height: calc(100vh - 33.541vw);

    }
    .modal-content {
        padding: 5.772vw 3.588vw 4.680vw;
    }
    #addIVRLabel small {
        font-size: 2.184vw;
        line-height: 3.432vw;
        margin-top: 1.559vw;
    }
    #root .modal-header {
        margin: 0 0 4.057vw;
    }
    #root .upload-file-design {
        margin-bottom: 2.964vw;
    }
    .ivr-modal .upload-file-design label {
        padding: 1.560vw 2.808vw;
    }
    #root .ivr-modal .forms-field {
        margin-bottom: 2.496vw;
    }
    #root .ivr-modal .btn {
        font-size: 1.871vw;
        line-height: 2.496vw;
        height: 6.241vw;
        padding: 0.625vw 0 0;
    }
    #root .modal-footer {
        top: -3.432vw;
        left: -0.156vw;
        margin-top: 4.680vw;
    }
    #root .modal-body.custom-position{
        top: -1.404vw
    }
    #root .modal-body.custom-position .forms-field{
        margin-bottom: 2.652vw;
    }
    #root .actionIcons .dropdown button {
        left: 2.145vw;
        top: 1.872vw;
    }
    #root .react-datepicker__input-container input {
        border-radius: 0.625vw;
        font-size: 2.029vw;
        padding-bottom: 0.155vw;
        height: 6.241vw;
        line-height: 2.496vw;
        padding-left: 2.496vw;
    }
    #root .user-management.ivr-management .row.user_table {
        padding-left: 1.248vw !important;
        padding-right: 0.468vw !important;
    }
    #root .user-management.ivr-management .row.user_table {
        padding-top: 3.276vw;
        padding-bottom: 2.652vw;

    }
    #root .user-management.ivr-management .row.user_table .col {
        padding-left: 2.340vw !important;
        padding-right: 2.340vw !important;
    }
    #root .header-generic {
        padding: 1.560vw 1.560vw;
    }
    #root .user_container .nav.nav-pills {
        margin-top: -0.156vw;
        margin-bottom: 3.588vw;
        border-bottom: 0.078vw solid #C2C2C2;
    }
    #root .user_data {
        margin-bottom: 0.936vw;
    }
    #root .user_management .more-icon-con {
        left: 2.652vw;
        top: -3.432vw;
    }
    #root .row.user_table .centered_content {
        max-width: 20.281vw;
    }
    #root .row.user_table .centered_content .ivr-grid-status{
        padding: 1.248vw 2.496vw 0.624vw;
        top: 0.468vw;
        font-size: 1.8vw;
    }
    #root .row.user_table.roles_table {
        padding-top: 3.120vw;
        padding-bottom: 2.808vw;
    }
    #root .clients_icon {
        top: 1.248vw;
    }
    #root .user_management .row.user_table.roles_table .user-actions.more-icon-con {
        left: -1.248vw;
        top: 3.120vw;
    }
    #root .user_management .row.user_table.roles_table .mobile-flex {
        margin-top: 3.900vw;
    }
    #root .clients-cust .responsiveTitle{
        position: relative;
        top: 0.468vw;
    }
    #root .grid-reg-text time{
        padding-left: 1.560vw;
        top: 0.156vw;
    }
    #root .grid-reg-text {
        font-size: 1.872vw;
        line-height: 2.496vw;
    }
    #root .pageTitle {
        margin-bottom: 3.432vw;
        font-size: 3.627vw;
        line-height: 4.095vw;
    }
    #root .box-text {
        font-size: 1.872vw;
        margin-bottom: 2.652vw;
        margin-top: 0.624vw;
        letter-spacing: 0.063vw;
        padding-right: 3.900vw;
        line-height: 3.432vw;
    }
    #root .version-grid-con .more-icon-con {
        left: -1.560vw;
        top: -1.872vw;
    }
    #root .call-icon {
        left: -2.652vw;
    }
    #root .version-pills .nav-heading{
        border-bottom: 0.078vw solid #C2C2C2;
    }
    #root .list-tabs li{
        font-size: 3.120vw;
        line-height: 3.744vw;
    }
    #root .list-tabs li:first-child{
        margin-right: 6.240vw;
    }
    #root .version-list li.active{
        border-radius: 0.624vw;
    }
    #root .version-list li.active:after{
        right: 3.120vw;
        font-size: 1.872vw;

    }
    .container-role [type="checkbox"]:checked + span::after, .container-role [type="checkbox"]:not(:checked) + span::after {
        width: 0.938vw;
        height: 1.874vw;
        border-bottom: 0.313vw solid #fff;
        border-right: 0.313vw solid #fff;
        top: 0.313vw;
        left: 1.093vw;
    }
    #root .version-list{
        margin-top: 2.496vw;
    }
    #root .version-list li{
        font-size: 2.496vw;
        line-height: 8.736vw;
        padding: 0 1.560vw;
    }
    #root .version-list li:hover{
        border-radius: 0.624vw;
        padding: 0 1.560vw;

    }
    #root .text-with-bg {
        padding: 2.341vw 3.745vw;
    }
    #root .schedule-icon {

        height: 14.977vw;
        width: 14.977vw;
        font-size: 5.304vw;

    }
    #root .text-with-bg h4 {
        font-size: 1.872vw;
        line-height: 2.184vw;
    }
    #root .text-with-bg h4 span {
        padding-top: 0.468vw;
    }
    #root .schedule-changes h1 {
        font-size: 2.496vw;
    }

    .user_management .row.user_table .ivr-grid-status {
        font-size: 1.248vw;
    }
    #root .user_management .roles_table .custom-margin {
        margin-top: 1.404vw;
    }
    #root .permissions_box {
        padding: 1.560vw 1.560vw 1.092vw;
        margin-right: 0.780vw;
        margin-bottom: 0.780vw;
        font-size: 1.559vw;
        line-height: 1.871vw;
    }
    #root .container-role [type="checkbox"]:checked + span::before,
    #root .container-role [type="checkbox"]:not(:checked) + span::before {

        width: 3.121vw;
        height: 3.121vw;
        border: 0.155vw solid #ddd;
    }
    #root .ivr-modal .mand-field {
        font-size: 1.871vw;
        line-height: 2.341vw;
    }
    #root .more-roles {
        font-size: 1.871vw;
        line-height: 2.496vw;
    }
    #root .user_management .user-actions.more-icon-con {
        left: 0.156vw;
        top: 1.248vw;
    }
    #root .dropdown-item.option-has-icon > span.icon-pencil-icon{
        font-size: 2.652vw;
    }
    #root .ivr-modal .roles-list-modal label {
        margin-bottom: 2.496vw;
    }
    #root .modal .plus-icon {
        right: 3.121vw;
    }
    #root .dropdown-item.option-has-icon .icon-upload-icon {
        font-size: 2.496vw;
    }

    #root .avatarWrapper .avatar-dropdown .dropdown-items .dropdown-item{
        padding: 4.992vw 4.368vw 4.836vw;
        font-size: 2.184vw;
        line-height: 3.120vw;
    }
    #root .avatarWrapper .avatar-dropdown .dropdown-items .dropdown-item span.icon{
        font-size: 3.120vw;
        padding-right: 3.120vw;
    }
    #root .avatarWrapper .avatar-dropdown .dropdown-items .dropdown-item span:not(.icon){
        padding: 0.624vw 0 0;
    }
    #root .avatarWrapper .avatar-dropdown .dropdown-items .dropdown-item:not(:last-child){
        border-bottom:0.156vw solid #E7E7E7;
    }
    #root .message-alert {
        bottom: 1.872vw;
    }
    #root .message-alert .message-text {
        padding: 0.624vw 2.028vw 0 1.092vw;
        font-size: 3.729vw;
    }

    #root .notification-header h1 {
        line-height: 3.120vw;
        font-size: 3.120vw;
    }
    #root .notification-header span {
        font-size: 1.871vw;
        line-height: 2.493vw;
        border-radius: 2.963vw;
        padding: 0.779vw 1.871vw 0.312vw;
    }
    #root .notification-body .new .notification-content h2 {
        font-size: 2.183vw;
        line-height: 2.496vw;
    }
    #root .notification-body .notification-content span {
        font-size: 1.871vw;
        line-height: 2.496vw;
    }
    #root .notification-content {
        margin-left: 1.871vw;
    }
    .notification-body .new p {
        font-size: 1.871vw;
    }
    .notification-body p {
        font-size: 1.871vw;
    }
    .notification-body .img-wrapper span {
        padding: 0.937vw;
        width: 4.991vw;
        height: 4.991vw;
    }
    .notification-body .img-wrapper.new::before {
        top: -0.155vw;
        left: 0.625vw;
        height: 0.937vw;
        width: 0.937vw;

    }.dropdown-item.option-has-icon p {
         font-size: 1.871vw;
         line-height: 2.341vw;
         padding: 0.312vw 0 0;
     }
    .dropdown-menu {
        border-radius: 0.625vw;
        padding: 2.341vw 0.779vw 2.341vw;
    }
    .more-icon-con .dropdown-item {
        height: 5.304vw;
        border-radius: 0.625vw;
        padding: 0 2.183vw;
    }

    .dropdown-item.option-has-icon > span {
        margin-right: 1.559vw;
        font-size: 2.963vw;
    }
    #root .grid-reg-text img {
        width: 2.496vw;
    }
    .dropdown-item {
        font-size: 2.029vw;
        line-height: 1.559vw;
    }
    #root .col.col-xl-3.grid-instance-column > img {
        min-width: 4.992vw;
        width: 4.992vw;
    }
    #root .notification-box {
        padding: 3.744vw 0;
    }
    #root .clients_icon a img {
        min-height: 4.367vw;
        min-width: 4.367vw;
    }
    #root .more-icons {
        border-radius: 3.732vw;
        min-height: 4.367vw;
        min-width: 4.367vw;
        max-width: 4.367vw;
        left: -6.347vw;
        font-size: 1.934vw;
        line-height: 3.468vw;
    }
    #root .clients_icon img:nth-child(5) {
        position: relative;
        left: -5.389vw;
    }
    #root .clients_icon img:nth-child(4) {
        position: relative;
        left: -4.042vw;
    }
    #root .clients_icon img:nth-child(3) {
        position: relative;
        left: -2.694vw;
    }
    #root .clients_icon img:nth-child(2) {
        left: -1.347vw;
    }
    .notification-body .notification-content h2 {
        font-size: 2.183vw;
        line-height: 2.496vw;
    }
    .header-notification span {
        font-size: 3.373vw;
    }
    .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(2.2vw);
    }
    .login-head .logo-area img {
        width: 12.999vw;
    }
    .login-box h1 {
        font-size: 4vw;
        line-height: 1.3;
    }
    #Log p {
        font-size: 2.4vw;
        padding-bottom: 2.135vw;
    }
}

#notificationDropdown img{
    width: 2.083vw;
}
