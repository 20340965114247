
.scrollbar-track-x,
.scrollbar-thumb-x {
    height: 5px !important;

}

.scrollbar-track-y{
    width: 5px !important;
    background: transparent !important;
    right: 8px !important;
}
.scrollbar-thumb-y {
    width: 5px !important;
    background: #DADADA !important;
    top: 20px !important;
    bottom: 20px !important;
}
